.divider {
    &-top,
    &-bottom,
    &-start,
    &-end {
        position: relative;

        &::before {
            content: '';
            background-color: $divider-color;
            display: block;
            position: absolute;
        }
    }

    &-0 {
        &::before {
            display: none;
        }
    }

    &-top,
    &-bottom {
        &::before {
            height: $divider-width;
            width: $divider-length;
            left: 50%;
            transform: translateX(-50%);
        }
    }

    &-start,
    &-end {
        &::before {
            height: $divider-length;
            width: $divider-width;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    &-top {
        margin-top: $divider-offset-sm * 2;

        &::before {
            top: -$divider-offset-sm;
        }
    }

    &-start {
        margin-left: $divider-offset-sm * 2;

        &::before {
            left: -$divider-offset-sm;
        }
    }

    &-bottom {
        margin-bottom: $divider-offset-sm * 2;

        &::before {
            bottom: -$divider-offset-sm;
        }
    }

    &-end {
        margin-right: $divider-offset-sm * 2;

        &::before {
            right: -$divider-offset-sm;
        }
    }

    &--md {
        &.divider-top {
            margin-top: $divider-offset-md * 2;

            &::before {
                top: -$divider-offset-md;
            }
        }

        &.divider-start {
            margin-left: $divider-offset-md * 2;

            &::before {
                left: -$divider-offset-md;
            }
        }

        &.divider-bottom {
            margin-bottom: $divider-offset-md * 2;

            &::before {
                bottom: -$divider-offset-md;
            }
        }

        &.divider-end {
            margin-right: $divider-offset-md * 2;

            &::before {
                right: -$divider-offset-md;
            }
        }
    }

    &--lg {
        &.divider-top {
            margin-top: $divider-offset-lg * 2;

            &::before {
                top: -$divider-offset-lg;
            }
        }

        &.divider-start {
            margin-left: $divider-offset-lg * 2;

            &::before {
                left: -$divider-offset-lg;
            }
        }

        &.divider-bottom {
            margin-bottom: $divider-offset-lg * 2;

            &::before {
                bottom: -$divider-offset-lg;
            }
        }

        &.divider-end {
            margin-right: $divider-offset-lg * 2;

            &::before {
                right: -$divider-offset-lg;
            }
        }
    }
}

@each $breakpoint in map-keys($grid-breakpoints) {
    .divider-#{$breakpoint} {
        @include media-breakpoint-up($breakpoint) {
            &-top,
            &-bottom,
            &-start,
            &-end {
                position: relative;

                &::before {
                    content: '';
                    background-color: $divider-color;
                    display: block;
                    position: absolute;
                }
            }

            &-0 {
                &::before {
                    display: none;
                }
            }

            &-top,
            &-bottom {
                &::before {
                    height: $divider-width;
                    width: $divider-length;
                    left: 50%;
                    transform: translateX(-50%);
                }
            }

            &-start,
            &-end {
                &::before {
                    height: $divider-length;
                    width: $divider-width;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }

            &-top {
                margin-top: $divider-offset-sm * 2;

                &::before {
                    top: -$divider-offset-sm;
                }

                &.divider--md {
                    margin-top: $divider-offset-md * 2;

                    &::before {
                        top: -$divider-offset-md;
                    }
                }

                &.divider--lg {
                    margin-top: $divider-offset-lg * 2;

                    &::before {
                        top: -$divider-offset-lg;
                    }
                }
            }

            &-start {
                margin-left: $divider-offset-sm * 2;

                &::before {
                    left: -$divider-offset-sm;
                }

                &.divider--md {
                    margin-left: $divider-offset-md * 2;

                    &::before {
                        left: -$divider-offset-md;
                    }
                }

                &.divider--lg {
                    margin-left: $divider-offset-lg * 2;

                    &::before {
                        left: -$divider-offset-lg;
                    }
                }
            }

            &-bottom {
                margin-bottom: $divider-offset-sm * 2;

                &::before {
                    bottom: -$divider-offset-sm;
                }

                &.divider--md {
                    margin-bottom: $divider-offset-md * 2;

                    &::before {
                        bottom: -$divider-offset-md;
                    }
                }

                &.divider--lg {
                    margin-bottom: $divider-offset-lg * 2;

                    &::before {
                        bottom: -$divider-offset-lg;
                    }
                }
            }

            &-end {
                margin-right: $divider-offset-sm * 2;

                &::before {
                    right: -$divider-offset-sm;
                }

                &.divider--md {
                    margin-right: $divider-offset-md * 2;

                    &::before {
                        right: -$divider-offset-md;
                    }
                }

                &.divider--lg {
                    margin-right: $divider-offset-lg * 2;

                    &::before {
                        right: -$divider-offset-lg;
                    }
                }
            }

            &--md {
                &.divider-top {
                    margin-top: $divider-offset-md * 2;

                    &::before {
                        top: -$divider-offset-md;
                    }
                }

                &.divider-start {
                    margin-left: $divider-offset-md * 2;

                    &::before {
                        left: -$divider-offset-md;
                    }
                }

                &.divider-bottom {
                    margin-bottom: $divider-offset-md * 2;

                    &::before {
                        bottom: -$divider-offset-md;
                    }
                }

                &.divider-end {
                    margin-right: $divider-offset-md * 2;

                    &::before {
                        right: -$divider-offset-md;
                    }
                }
            }

            &--lg {
                &.divider-top {
                    margin-top: $divider-offset-lg * 2;

                    &::before {
                        top: -$divider-offset-lg;
                    }
                }

                &.divider-start {
                    margin-left: $divider-offset-lg * 2;

                    &::before {
                        left: -$divider-offset-lg;
                    }
                }

                &.divider-bottom {
                    margin-bottom: $divider-offset-lg * 2;

                    &::before {
                        bottom: -$divider-offset-lg;
                    }
                }

                &.divider-end {
                    margin-right: $divider-offset-lg * 2;

                    &::before {
                        right: -$divider-offset-lg;
                    }
                }
            }
        }
    }
}

.divider--short {
    &.divider {
        &-top,
        &-bottom {
            &::before {
                width: $divider-length-short;
            }
        }

        &-start,
        &-end {
            &::before {
                height: $divider-length-short;
            }
        }
    }
}
