@import 'node_modules/highcharts/css/highcharts';

// Highcharts overrides
highcharts-chart {
    display: block;
    width: 100%;
    height: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
