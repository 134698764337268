// Body
body {
    position: relative;
    -webkit-font-smoothing: antialiased;
}

// Links
a {
    color: $link-color;
    font-weight: $link-font-weight;

    &:hover {
        color: $link-hover-color;
    }

    &:not([href]) {
        color: inherit;
        text-decoration: none;
        font-weight: inherit;
    }

    &.link {
        color: $link-color;
        font-weight: $link-font-weight;
        &:hover {
            text-decoration: underline;
        }
    }
}

// Tables
caption {
    color: $table-caption-color;
}
