:root {
    @include rgb-vars(primary, 51, 86, 105);
    @include rgb-vars(secondary, 158, 216, 208);
    @include rgb-vars(success, 75, 141, 139);
    @include rgb-vars(danger, 235, 83, 69);
    @include rgb-vars(warning, 253, 126, 20);
    @include rgb-vars(info, 108, 136, 131);

    @include rgb-vars(primary-light, 125, 153, 172);
    @include rgb-vars(secondary-light, 234, 246, 245);
    @include rgb-vars(success-light, 215, 230, 229);
    @include rgb-vars(danger-light, 252, 220, 220);
    @include rgb-vars(warning-light, 255, 231, 199);
    @include rgb-vars(info-light, 230, 233, 234);

    @include rgb-vars(primary-dark, 31, 52, 64);
    @include rgb-vars(secondary-dark, 34, 85, 78);
    @include rgb-vars(success-dark, 46, 86, 85);
    @include rgb-vars(danger-dark, 147, 57, 58);
    @include rgb-vars(warning-dark, 143, 124, 94);
    @include rgb-vars(info-dark, 87, 93, 98);
}
