@import 'bootstrap/scss/grid';

.grid {
    // Reset grid columns and rows for when the grid is within a dashboard grid
    --#{$variable-prefix}columns: #{$grid-columns};
    --#{$variable-prefix}rows: null;
}

.grid-auto-rows {
    // Allows automatically wrapped rows to size themselves rather than adhering to the grid
    grid-template-rows: none;
    grid-auto-rows: minmax(min-content, max-content);
}

// Handle responsive grids based on the window breakpoints as well as the state of the global nav
// Will likely move back out of this library once I have a basis for where the more specific code
// should live, but it's fine here for the time being
.global-nav-menu-collapsed {
    @each $breakpoint in map-keys($grid-breakpoints) {
        @if ($breakpoint != 'xs') {
            @each $col in (1, 2, 3, 4, 6, 12) {
                .g-collapsed-col-#{$col} {
                    @extend .g-col-#{$col};
                }
                .g-collapsed-col-#{$breakpoint}-#{$col} {
                    @extend .g-col-#{$breakpoint}-#{$col};
                }
            }
        }
    }
}
