@use 'wdx';

.ag-theme-quartz,
.ag-theme-quartz-dark {
    --ag-background-color: var(--bs-body-bg);
    --ag-row-hover-color: var(--bs-body-bg-muted);
    --ag-font-family: wdx.$font-family-base;
    --ag-font-size: 0.875em;
    --ag-header-background-color: var(--bs-body-bg);
    --ag-cell-horizontal-padding: 0.75em;

    &.wdx-ag-grid-shade-groups {
        .ag-row.ag-row-group {
            background-color: wdx.$body-bg-muted;
        }
    }

    .ag-filter-filter .ag-input-field-input.ag-text-field-input,
    .ag-ltr .ag-row-group-indent-1 {
        padding-left: 1rem !important;
    }
}
