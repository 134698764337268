@import 'bootstrap/scss/dropdown';

.dropdown-menu {
    &.dropdown-static {
        display: block;
        position: relative;
    }
    &.dropdown-flush {
        display: block;
        position: relative;
        min-width: unset;
        box-shadow: none;
        border-radius: 0;
        border: none;

        li,
        li > .dropdown-item {
            border-radius: 0;
        }
    }
    &.popover {
        font-size: $dropdown-font-size;
    }

    &:not(.dropdown-static):not(.dropdown-flush) {
        min-width: $dropdown-min-width;
        max-width: $dropdown-max-width;
        max-height: $dropdown-max-height;
        overflow-y: auto;

        &.dropdown-menu-lg {
            min-width: $dropdown-min-width-lg;
        }

        .dropdown-scroll-wrapper {
            height: 100%;
            max-height: $dropdown-max-height;
            overflow: hidden;
        }
    }

    li:not(:first-child) .dropdown-item {
        @include border-top-radius(0);
    }

    li:not(:last-child) .dropdown-item {
        @include border-bottom-radius(0);
    }

    @include scrollbar($dropdown-bg);
}

.dropdown-header {
    font-size: $dropdown-font-size;
    font-weight: $dropdown-header-font-weight;

    &:not(:first-child) {
        border-top: $dropdown-border;
    }
}

.dropdown-item {
    @include transition(
        background-color $transition-duration-base $transition-curve-base,
        border-color $transition-duration-base $transition-curve-base
    );
}

.dropdown-header,
.dropdown-item:not(:last-child),
li .dropdown-item {
    border-bottom: $dropdown-border;
}
