@use 'wdx';
@import 'scss/highcharts_variables';
@import 'node_modules/highcharts/css/highcharts';

// Highcharts overrides
highcharts-chart {
    display: block;
    width: 100%;
    height: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.highcharts-background,
.highcharts-tooltip-box {
    fill: wdx.$body-bg;
}

.highcarts-background-muted {
    .highcharts-background {
        fill: wdx.$body-bg-muted;
    }
}

.highcharts-title,
.highcharts-axis-labels,
.highcharts-axis-title,
.highcharts-caption,
.highcharts-label > text,
.highcharts-plot-band-label,
.highcharts-legend-item > text {
    fill: wdx.$body-color;
}

.highcharts-subtitle,
.highcharts-legend-series-active .highcharts-legend-item > text {
    fill: wdx.$body-color-muted;
}

.highcharts-no-tooltip,
.highcharts-label-box,
.highcharts-menu-item {
    color: wdx.$body-color;
}

.highcharts-menu {
    background-color: wdx.$body-bg;
}

.highcharts-title,
.highcharts-axis-title,
.highcharts-no-tooltip {
    font-weight: 600;
}

.highcharts-tooltip {
    stroke-opacity: 0.4;
}

.highcharts-title {
    font-size: 1.7rem;
}

// Menu
.highcharts-menu {
    .highcharts-menu-item {
        padding: 0.8em;
        &:hover {
            color: wdx.$light-contrast;
            background: wdx.$light;
        }
    }
}

.clmi-bullet-graph {
    height: 8rem;

    .highcharts-tracker {
        .highcharts-point {
            fill: wdx.$light-contrast;
            stroke: wdx.$light;
        }
        .highcharts-data-label {
            fill: wdx.$light;
        }
    }
}

.highcharts-plot-band.bg-success {
    fill: wdx.$success;
    fill-opacity: 0.8;
}
.highcharts-plot-band.bg-warning {
    fill: wdx.$warning;
    fill-opacity: 0.8;
}
.highcharts-plot-band.bg-danger {
    fill: wdx.$danger;
    fill-opacity: 0.8;
}

// Shared CLMi styles

.clmi-chart-loader {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 40rem;
    height: 100%;
    width: 100%;
}
