// mouse, touch pad only
@media (hover: hover) {
    :root {
        @include scrollbar();
    }

    /* The emerging W3C standard
    that is currently Firefox-only */
    * {
        scrollbar-width: thin;
        scrollbar-color: var(--scrollbar-thumb-bg) var(--scrollbar-bg);
    }
    /* Works on Chrome/Edge/Safari */
    *::-webkit-scrollbar {
        width: $scrollbar-width;
    }
    *::-webkit-scrollbar-track {
        background: var(--scrollbar-bg);
    }
    *::-webkit-scrollbar-thumb {
        background-color: var(--scrollbar-thumb-bg);
        border-radius: $scrollbar-border-radius;
        border: $scrollbar-border-width solid var(--scrollbar-bg);

        &:hover {
            background-color: var(--scrollbar-thumb-bg-hover);
        }
    }
}

@each $color, $value in $theme-colors {
    $contrast: map-get($theme-colors-contrast, $color);
    .bg-#{$color} {
        @include scrollbar($value, $contrast, $contrast);
    }
}
