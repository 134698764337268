@import 'bootstrap/scss/card';

.card-clickable {
    cursor: pointer;

    &,
    .card-header,
    .card-footer {
        @include transition();
    }

    &:hover {
        &,
        .card-header,
        .card-footer {
            border-color: $primary;
        }
    }
}

.card hr {
    background-color: $body-color-muted;
    color: $body-color-muted;
}

.card-body:empty {
    display: none;
}

.card-muted {
    @include card-body($body-bg-muted, $body-color);
}

.card-bold {
    @include card-body($body-bg-strong, $body-color);
}

// themed cards
@each $clr, $value in $theme-colors {
    $bg: $value;
    $color: map-get($theme-colors-contrast, $clr);
    $header-bg: map-get($theme-colors-light, $clr);
    $header-color: map-get($theme-colors-light-contrast, $clr);
    $border: $value;

    .card-#{$clr} {
        @include card-body($bg, $color, $header-bg, $header-color, $border);
    }
}

.card-img-bg-cover {
    min-height: $card-img-cover-height;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.card-text + .card-text {
    margin-top: $card-title-spacer-y;
}
