@import 'bootstrap/scss/accordion';

.accordion-button {
    &::after {
        margin-left: $accordion-padding-x;
    }
    &.disabled {
        &::after {
            display: none;
        }
    }
    &::after {
        content: '\f078';
        @include fa-icon();
        font-size: $accordion-icon-width;
        color: inherit;
        background: $transparent;
        height: unset;
        width: unset;
    }
    &:not(.collapsed)::after {
        background: $transparent;
    }
}

.accordion-footer {
    display: none;

    &.show {
        display: block;
    }

    .accordion-button {
        box-shadow: inset 0 ($accordion-border-width) 0 $accordion-border-color;

        &::after {
            transform: rotate(270deg);
        }
    }
}

.accordion-widget {
    @extend .accordion-flush;

    .accordion-button,
    .accordion-body {
        padding: $widget-spacer-y $widget-spacer-x;
    }

    .widget.widget-body,
    .widget-body {
        padding: 0;

        .widget.widget-body,
        .widget-body {
            @extend .widget-body;
        }
    }
}
