@import 'bootstrap/scss/navbar';

.navbar {
    height: $navbar-height;
    min-height: $navbar-height;

    .container-fluid {
        width: 100%;
    }
}

.navbar-brand {
    display: flex;
    align-items: center;

    &,
    img {
        max-height: $navbar-brand-height;
    }
}
