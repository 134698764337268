.dot-loader {
    --bs-dot-loader-margin-y: #{$dot-loader-margin-y};

    position: relative;
    width: var(--dot-loader-size, $dot-loader-size);
    height: var(--dot-loader-size, $dot-loader-size);
    border-radius: 100%;
    background-color: $dot-loader-bg-active;
    color: $dot-loader-bg-active;
    animation: dot-loader $dot-loader-duration infinite linear alternate;
    animation-delay: calc($dot-loader-duration * 0.5);
    margin-left: calc(
        var(--dot-loader-size, $dot-loader-size) + $dot-loader-gap
    );
    margin-right: calc(
        var(--dot-loader-size, $dot-loader-size) + $dot-loader-gap
    );
    margin-top: var(--bs-dot-loader-margin-y);
    margin-bottom: var(--bs-dot-loader-margin-y);

    &:empty {
        display: block !important;
    }
}

.dot-loader::before,
.dot-loader::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
}

.dot-loader::before {
    left: calc(
        (var(--dot-loader-size, $dot-loader-size) + $dot-loader-gap) * -1
    );
    width: var(--dot-loader-size, $dot-loader-size);
    height: var(--dot-loader-size, $dot-loader-size);
    border-radius: 100%;
    background-color: $dot-loader-bg-active;
    color: $dot-loader-bg-active;
    animation: dot-loader $dot-loader-duration infinite alternate;
    animation-delay: 0s;
}

.dot-loader::after {
    left: calc(var(--dot-loader-size, $dot-loader-size) + $dot-loader-gap);
    width: var(--dot-loader-size, $dot-loader-size);
    height: var(--dot-loader-size, $dot-loader-size);
    border-radius: 100%;
    background-color: $dot-loader-bg-active;
    color: $dot-loader-bg-active;
    animation: dot-loader $dot-loader-duration infinite alternate;
    animation-delay: $dot-loader-duration;
}
