//
// Base styles
//

.btn {
    display: inline-block;
    font-family: $btn-font-family;
    font-weight: $btn-font-weight;
    line-height: $btn-line-height;
    color: $body-color;
    text-align: center;
    text-decoration: if($link-decoration == none, null, none);
    white-space: $btn-white-space;
    vertical-align: middle;
    cursor: if($enable-button-pointers, pointer, null);
    user-select: none;
    background-color: transparent;
    border: $btn-border-width solid transparent;
    @include button-size(
        $btn-padding-y,
        $btn-padding-x,
        $btn-font-size,
        $btn-border-radius
    );
    @include transition($btn-transition);

    &:hover {
        text-decoration: if($link-hover-decoration == underline, none, null);
    }

    .btn-check:focus + &,
    &:focus {
        outline: 0;
        box-shadow: $btn-focus-box-shadow;
    }

    .btn-check:checked + &,
    .btn-check:active + &,
    &:active,
    &.active {
        @include box-shadow($btn-active-box-shadow);

        &:focus {
            @include box-shadow($btn-focus-box-shadow, $btn-active-box-shadow);
        }
    }

    &:disabled,
    &.disabled,
    fieldset:disabled & {
        cursor: not-allowed;
        opacity: $btn-disabled-opacity;
        @include box-shadow(none);
    }
}

//
// Alternate buttons
//

@each $color, $value in $theme-colors {
    $clr: $value;
    $contrast: map-get($theme-colors-contrast, $color);
    $clr-hover: map-get($theme-colors-hover, $color);
    $contrast-hover: map-get($theme-colors-hover-contrast, $color);
    $clr-accessible: map-get($theme-colors-accessible, $color);
    .btn-#{$color} {
        background-color: $clr;
        border-color: $clr;
        color: $contrast;

        &:hover {
            background-color: $clr-hover;
            border-color: $clr-hover;
            color: $contrast-hover;
        }
    }

    .btn-outline-#{$color} {
        background-color: $body-bg;
        border-color: $clr-accessible;
        color: $clr-accessible;

        &:hover {
            border-color: $clr;
            background-color: $clr;
            color: $contrast;
        }
    }
}

// A derivative of .btn-link that is designed to seamlessly fit into a block of text
.btn-inline {
    --btn-inline-max-width: #{$btn-inline-max-width};

    @extend .btn-link;
    @extend .text-truncate;
    font-size: inherit;
    line-height: inherit;
    vertical-align: baseline;
    padding: 0px;
    margin: 0px;
    max-width: var(--btn-inline-max-width);
}

//
// Button Sizes
//

.btn-lg {
    @include button-size(
        $btn-padding-y-lg,
        $btn-padding-x-lg,
        $btn-font-size-lg,
        $btn-border-radius-lg
    );
}

.btn-sm {
    @include button-size(
        $btn-padding-y-sm,
        $btn-padding-x-sm,
        $btn-font-size-sm,
        $btn-border-radius-sm
    );
}

//
// Link buttons
//

// Make a button look and behave like a link
.btn-link {
    font-weight: $link-font-weight;
    color: $link-color;
    text-decoration: $link-decoration;
    padding: 0;
    border: none;
    border-radius: 0;

    &:hover {
        color: $link-hover-color;
        text-decoration: $link-hover-decoration;
    }

    &:focus {
        text-decoration: $link-hover-decoration;
        @include box-shadow(none);
    }

    &:disabled,
    &.disabled {
        color: $btn-link-disabled-color;
    }

    &.active {
        @include box-shadow(none);
    }

    // No need for an active state here
}

//
// Icon Button
//

.btn-icon {
    padding: 0;
    margin: 0;
    @include border-radius(100%);
    overflow: hidden;
}

[wdxIconButton] {
    --icon-size: #{map-get($icon-sizes, md)};
    --icon-container-size: #{map-get($icon-container-sizes, md)};
    --fa-display: block;

    &.btn-sm {
        --icon-size: #{map-get($icon-sizes, sm)};
        --icon-container-size: #{map-get($icon-container-sizes, sm)};
    }

    &.btn-lg {
        --icon-size: #{map-get($icon-sizes, lg)};
        --icon-container-size: #{map-get($icon-container-sizes, lg)};
    }

    font-size: var(--icon-size);
    height: var(--icon-container-size);
    width: var(--icon-container-size);
    display: flex;
    align-items: center;
    justify-content: center;
}
