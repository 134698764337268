@import 'bootstrap/scss/toasts';

.toast-container {
    display: block;
    position: fixed;
    bottom: $toast-padding-x;
    left: 50%;
    top: 10%;
    transform: translateX(-50%);
    z-index: $toast-z-index;
}

@each $color, $value in $theme-colors {
    .toast-#{$color} {
        background-color: $value;
        border-color: $value;
        color: map-get($theme-colors-contrast, $color);

        .toast-header {
            background-color: map-get($theme-colors-light, $color);
            border-color: $value;
            color: map-get($theme-colors-light-contrast, $color);
        }

        &,
        .toast-header {
            a,
            a:hover {
                color: map-get($theme-colors-contrast, $color);
            }
        }
    }
}
