@use 'wdx';

.ng-select {
    &,
    &.ng-select-multiple {
        &.ng-invalid.ng-touched:not(.ng-select-opened) .ng-select-container {
            border-color: wdx.$form-feedback-invalid-color;
        }

        .ng-select-container {
            color: wdx.$input-color;
            background-color: wdx.$input-bg;
            border-radius: wdx.$input-border-radius;
            border: wdx.$input-border-width solid wdx.$input-border-color;

            .ng-value-container {
                .ng-input > input {
                    color: wdx.$input-color;
                    background-color: wdx.$input-bg;
                }

                .ng-placeholder {
                    color: wdx.$input-color;
                    margin-top: 0.25rem;
                }
            }
        }

        .ng-value-container {
            .hide-on-trigger {
                display: none;
            }
        }

        &.ng-select-opened > .ng-select-container {
            color: wdx.$input-color;
            background-color: wdx.$input-bg;
        }

        &.ng-select-disabled > .ng-select-container {
            background-color: wdx.$input-disabled-bg;
        }

        &.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
            color: wdx.$input-focus-color;
            background-color: wdx.$input-focus-bg;
            border-color: wdx.$input-focus-border-color;
            outline: 0;
            box-shadow: wdx.$input-focus-box-shadow;
        }
    }
}

.ng-dropdown-panel .ng-dropdown-panel-items {
    .ng-option {
        background-color: wdx.$dropdown-bg;
        color: wdx.$dropdown-color;

        &.ng-option-marked {
            background-color: wdx.$dropdown-link-hover-bg;
            color: wdx.$dropdown-link-hover-color;
        }

        &.ng-option-selected,
        &.ng-option-selected.ng-option-marked {
            background-color: wdx.$dropdown-link-active-bg;
            color: wdx.$dropdown-link-active-color;
        }

        .ng-tag-label {
            font-weight: wdx.$font-weight-bold;
            font-size: 100%;
        }
    }
}

.ng-dropdown-panel .scroll-host {
    @include wdx.scrollbar(wdx.$dropdown-bg);
}

.ng-dropdown-footer {
    background-color: wdx.$input-bg;
}

.ng-optgroup .ng-option-label {
    font-weight: bold;
}

.input-group {
    .ng-select,
    .ng-select-container {
        position: unset;
    }

    &:not(.has-validation) {
        > .ng-select:not(:last-child) {
            .ng-select-container {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }
        }

        > .ng-select:not(:first-child) {
            .ng-select-container {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }
        }
    }
}

.select-options-wrap {
    .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
        white-space: unset;
        overflow: unset;
        text-overflow: unset;
    }

    &.ng-select.ng-select-multiple
        .ng-select-container
        .ng-value-container
        .ng-value {
        display: flex;
        white-space: unset;

        .ng-value-icon {
            display: flex;
            align-items: center;
        }
    }
}
