.datepicker {
    &.dropdown-menu {
        padding: $datepicker-padding;
    }

    &,
    .ngb-dp-header,
    .ngb-dp-content,
    .ngb-dp-week,
    .ngb-dp-weekdays,
    .ngb-dp-month-name {
        background-color: $datepicker-bg;
    }

    .ngb-dp-day,
    .ngb-dp-weekday,
    .ngb-dp-week-number {
        margin: $datepicker-day-margin;
        height: $datepicker-day-size;
        width: $datepicker-day-size;
    }

    .ngb-dp-navigation-select {
        flex: 1 1 $datepicker-navigation-flex-basis;
    }

    .form-select {
        @extend .btn;
        @extend .btn-link;
        padding: 0;
        box-shadow: none;
        text-align: start;

        &:first-child {
            margin-right: 0.5rem;
        }

        &.active,
        &:focus,
        &:active {
            border: none;
            box-shadow: none;
        }
    }

    .btn[disabled] {
        border: none;
    }

    .ngb-dp-day,
    .ngb-dp-today {
        &,
        &.active,
        &:focus,
        &:active {
            border: none;
            outline: none;
        }

        > [ngbdatepickerdayview],
        > .custom-day {
            height: $datepicker-day-size;
            width: $datepicker-day-size;
            padding: $datepicker-day-padding;
            border-radius: 0;

            &.btn-light {
                background-color: $transparent;
                color: $body-color;

                &:hover,
                &:not(:disabled):not(.disabled):active,
                &:not(:disabled):not(.disabled).active {
                    background-color: $datepicker-hover-bg;
                    color: $datepicker-hover-color;
                }

                &.bg-primary {
                    background-color: $datepicker-select-bg !important;
                    color: $datepicker-select-color;
                }
            }
        }

        > .custom-day {
            display: inline-block;
            text-align: center;

            &:hover {
                background-color: $datepicker-hover-bg;
                color: $datepicker-hover-color;
            }

            &.range {
                background-color: $datepicker-select-bg;
                color: $datepicker-select-color;

                &.faded {
                    background-color: $datepicker-hover-bg;
                    color: $datepicker-hover-color;
                }
            }
        }
    }

    .ngb-dp-weekdays {
        border-bottom: none;
        margin-top: $datepicker-days-margin-top;
    }

    .ngb-dp-weekday {
        font-style: normal;
        @extend .h6;
        color: $body-color;
        margin-bottom: 0;
    }

    .ngb-dp-month-name {
        @extend .h5;
    }

    .ngb-dp-months {
        margin-top: $datepicker-months-margin-top;

        .ngb-dp-month:not(:last-child) {
            margin-right: calc($datepicker-month-gutter / 2);
            padding-right: calc($datepicker-month-gutter / 2);
            border-right: $datepicker-month-border;
        }
    }

    .ngb-tp {
        display: flex;
        justify-content: center;
        border-top: $timepicker-border;
        margin-top: calc($timepicker-gutter / 2);
        padding-top: calc($timepicker-gutter / 2);

        .ngb-tp-input.form-control {
            padding: 0;
        }
    }
}

.ngb-tp {
    .btn-link,
    .btn-outline-primary {
        border: 0;
        background-color: $transparent;
        padding: $timepicker-chevron-button-padding;

        &:disabled,
        &[disabled] {
            cursor: not-allowed;

            &:hover,
            &:focus,
            &:active {
                border: 0;
            }
        }
    }

    .btn-outline-primary:not(:disabled):not(.disabled) {
        @extend .btn-link;

        &:hover,
        &:focus,
        &:active,
        &.active {
            background-color: $transparent;
            border: 0;
            outline: 0;
            text-decoration: none;
            color: $link-hover-color;
            font-weight: $font-weight-bold;
        }
    }

    .ngb-tp-input.form-control {
        padding: 0;
    }
}
