.input-wrapper__content:not(.is-invalid).has-warning {
    .form-control {
        border-color: $form-feedback-warning-color;
    }
}

.form-control {
    &:disabled,
    &[readonly] {
        &.appear-editable {
            background-color: $input-bg;
        }
    }
}

input[type='date'],
input[type='time'],
input[type='datetime-local'],
input[type='month'] {
    &.form-control {
        &.is-invalid {
            padding-right: 0;
        }
    }
}
