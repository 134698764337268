.input-switch {
    margin: 0;

    .switch-checkbox {
        visibility: hidden;
        position: absolute;
        height: 0;
        width: 0;

        &:checked ~ .switch {
            background-color: $input-switch-container-active-bg;
            border-color: $input-switch-container-border-active-color;

            &::before {
                left: $input-switch-active-left;
            }
        }

        &:disabled ~ .switch {
            cursor: not-allowed;
        }

        &:checked:disabled ~ .switch {
            background-color: $input-switch-container-active-disabled-bg;
            border-color: $input-switch-container-border-active-disabled-color;
        }
    }

    .switch {
        display: block;
        position: relative;
        height: $input-switch-container-height;
        width: $input-switch-container-width;
        border: $input-switch-container-border;
        background-color: $input-switch-container-bg;
        border-radius: $input-switch-container-height;
        cursor: pointer;
        @include transition();

        &::before {
            content: '';
            height: $input-switch-size;
            width: $input-switch-size;
            display: block;
            position: absolute;
            background-color: $input-switch-bg;
            border-radius: 100%;
            border: $input-switch-border;
            top: $input-switch-top;
            left: $input-switch-left;
            @include box-shadow($box-shadow);
            @include transition();
        }
    }
}
