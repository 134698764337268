table.timeline {
    position: relative;
    z-index: 0;

    .timeline-cell {
        padding: $timeline-cell-spacer-y $timeline-cell-spacer-x;
    }

    .timeline-icon-cell {
        position: relative;
        z-index: 0;

        &::before {
            content: '';
            display: block;
            position: absolute;
            z-index: -1;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            height: 100%;
            width: $timeline-line-width;
            background-color: $timeline-line-color;
        }

        .timeline-icon {
            display: block;
            background-color: $timeline-icon-bg;
            color: $timeline-icon-color;
            border: $timeline-icon-border;
            border-radius: 100%;
        }
    }
}
