@mixin utility-color-class($color, $variable: null) {
    @if $variable == null {
        $variable: $color;
    }
    .text-#{$color} {
        --#{$variable-prefix}text-opacity: 1;
        color: rgba(
            var(--#{$variable-prefix}#{$variable}-rgb),
            var(--#{$variable-prefix}text-opacity)
        ) !important;
    }
}

@mixin utility-bg-class($color, $variable: null) {
    @if $variable == null {
        $variable: $color;
    }
    .bg-#{$color} {
        $colorRgbaVar: rgba(
            var(--#{$variable-prefix}#{$variable}-rgb),
            var(--#{$variable-prefix}bg-opacity)
        ) !important;
        --#{$variable-prefix}bg-opacity: 1;
        --#{$variable-prefix}avatar-group-border-color: #{$colorRgbaVar};
        background-color: $colorRgbaVar;
        @include scrollbar($colorRgbaVar);
    }
}

@mixin absolute-center() {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@mixin remove-scrollbar() {
    &::-webkit-scrollbar {
        width: 0;
        height: 0;
    }
}

@mixin truncate-text-after-lines($lines) {
    display: -webkit-box;
    -webkit-line-clamp: $lines;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
