//
// Base styles
//

.alert {
    --alert-bg: #{$alert-bg};
    --alert-color: #{$alert-color};
    --alert-border-color: #{$transparent};

    position: relative;
    padding: $alert-padding-y $alert-padding-x;
    margin-bottom: $alert-margin-bottom;
    border: $alert-border-width solid var(--alert-border-color);
    color: var(--alert-color);
    background-color: var(--alert-bg);
    @include border-radius($alert-border-radius);

    .alert-link {
        color: var(--alert-color);
    }
}

// Headings for larger alerts
.alert-heading {
    // Specified to prevent conflicts of changing $headings-color
    color: inherit;
}

// Provide class for links that match alerts
.alert-link {
    font-weight: $alert-link-font-weight;
}

// Dismissible alerts
//
// Expand the right padding and account for the close button's positioning.

.alert-dismissible {
    padding-right: $alert-dismissible-padding-r;

    // Adjust close link position
    .btn-close {
        position: absolute;
        top: 0;
        right: 0;
        z-index: $stretched-link-z-index + 1;
        padding: $alert-padding-y * 1.25 $alert-padding-x;
    }
}

// Generate contextual modifier classes for colorizing the alert.

@each $color, $value in $theme-colors {
    .alert-#{$color} {
        --alert-bg: #{$value};
        --alert-color: #{map-get($theme-colors-contrast, $color)};
        --alert-border-color: #{$value};
    }
}

@each $color, $value in $theme-colors-light {
    .alert-#{$color}-light {
        --alert-bg: #{$value};
        --alert-color: #{map-get($theme-colors-light-contrast, $color)};
        --alert-border-color: #{$value};
    }
}

@each $color, $value in $theme-colors-dark {
    .alert-#{$color}-dark {
        --alert-bg: #{$value};
        --alert-color: #{map-get($theme-colors-dark-contrast, $color)};
        --alert-border-color: #{$value};
    }
}
