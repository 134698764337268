@import 'bootstrap/scss/nav';

.nav-tabs {
    overflow-x: auto;
    overflow-y: hidden;
    flex-wrap: nowrap;

    .nav-link {
        padding-top: $nav-link-padding-y + $nav-tabs-link-border-width;
        border-bottom: $nav-tabs-link-border;
        white-space: nowrap;

        &:hover,
        &:focus {
            background-color: $nav-tabs-link-hover-bg;
        }

        &.active {
            border-bottom-color: $nav-tabs-link-active-border-color;
        }
    }

    ~ div .action-buttons {
        padding: 0 $nav-link-padding-x;
    }
}

.side-nav {
    .nav-tabs {
        .nav-link {
            border-left: $nav-tabs-link-border;
            border-bottom: 0;
            padding: $nav-side-tabs-link-padding-y $nav-side-tabs-link-padding-x;

            &.active {
                border-left-color: $nav-tabs-link-active-border-color;
            }
        }
    }
}
