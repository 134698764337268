@keyframes skeleton-loader {
    0% {
        left: -100%;
    }
    50% {
        left: 0%;
    }
    100% {
        left: 100%;
    }
}
