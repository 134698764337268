@use 'wdx';

:root {
    --highcharts-color-0: #335669;
    --highcharts-color-1: #9ed8d0;
    --highcharts-color-2: #4b8d8b;
    --highcharts-color-3: #3070a2;
    --highcharts-color-4: #fad8d9;
    --highcharts-color-5: #f5e5cc;
    --highcharts-color-6: #f15e5f;
    --highcharts-color-7: #4c4c4c;

    --highcharts-color-contrast-0: #ffffff;
    --highcharts-color-contrast-1: #333333;
    --highcharts-color-contrast-2: #ffffff;
    --highcharts-color-contrast-3: #ffffff;
    --highcharts-color-contrast-4: #333333;
    --highcharts-color-contrast-5: #333333;
    --highcharts-color-contrast-6: #ffffff;
    --highcharts-color-contrast-7: #4c4c4c;
}

// Main segment colors
$colors: var(--highcharts-color-0) var(--highcharts-color-1)
    var(--highcharts-color-2) var(--highcharts-color-3)
    var(--highcharts-color-4) var(--highcharts-color-5)
    var(--highcharts-color-6) var(--highcharts-color-7);

$colors-contrast: var(--highcharts-color-contrast-0)
    var(--highcharts-color-contrast-1) var(--highcharts-color-contrast-2)
    var(--highcharts-color-contrast-3) var(--highcharts-color-contrast-4)
    var(--highcharts-color-contrast-5) var(--highcharts-color-contrast-6)
    var(--highcharts-color-contrast-7);

// Fonts
$font-family: wdx.$font-family-sans-serif;
