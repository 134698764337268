// Overwriting how form-check inputs are rendered to make them respect the css-variable theme

.form-check {
    label,
    input,
    input::after {
        cursor: pointer;
    }

    label {
        white-space: normal;
    }
}

.form-check-input,
.radio-tile > input,
.check-tile > input {
    &:not(.disabled),
    &:not([disabled]) {
        cursor: pointer;
    }

    &,
    + label > .bullet {
        position: relative;
        background-color: $form-check-input-bg;
        border: $form-check-input-border;
        height: $form-check-input-width;
        width: $form-check-input-width;
        @include transition();
    }

    &::after,
    + label > .bullet::after {
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: 0;
        @include transition();
    }

    &[type='checkbox'],
    &[type='checkbox'] + label > .bullet {
        @include border-radius($form-check-input-border-radius);
    }

    &[type='radio'],
    &[type='radio'] + label > .bullet {
        border-radius: $form-check-radio-border-radius;
    }

    &[type='checkbox'] {
        &::after,
        + label > .bullet::after {
            @include fa-icon(bold);
            color: $form-check-input-checked-color;
            font-size: 0.7em;
        }

        &:checked::after,
        + label > .bullet::after {
            // fontawesome checkmark glyph
            content: '\f00c';
        }
    }

    &[type='radio'] {
        &::after,
        + label > .bullet::after {
            content: '';
            border-radius: 100%;
            height: 0.5em;
            width: 0.5em;
            background-color: $form-check-input-checked-color;
        }
    }

    &:checked,
    &:checked + label > .bullet {
        background-color: $form-check-input-checked-bg-color;
        border-color: $form-check-input-checked-border-color;

        &[type='checkbox'],
        &[type='radio'],
        &[type='checkbox'] + label > .bullet,
        &[type='radio'] + label > .bullet {
            background-image: none;

            &::after {
                opacity: 1;
            }
        }
    }

    &[type='checkbox']:indeterminate,
    &[type='checkbox']:indeterminate + label > .bullet {
        background-color: $form-check-input-indeterminate-bg-color;
        border-color: $form-check-input-indeterminate-border-color;
        background-image: none;

        &::after {
            // fontawesome dash glyph
            content: '\e404';
            opacity: 1;
        }
    }

    &:disabled {
        pointer-events: none;
        filter: none;
        opacity: $form-check-input-disabled-opacity;
    }

    // Use disabled attribute in addition of :disabled pseudo-class
    // See: https://github.com/twbs/bootstrap/issues/28247
    &[disabled],
    &:disabled {
        ~ .form-check-label {
            opacity: $form-check-label-disabled-opacity;
        }
    }
}

// Check tiles
.radio-tile,
.check-tile {
    [type='checkbox']:checked,
    [type='checkbox']:not(:checked),
    [type='radio']:checked,
    [type='radio']:not(:checked) {
        @include visually-hidden();
    }
}

.input-radio-tiles,
.input-check-tiles {
    display: flex;
    flex-wrap: wrap;
    margin: 0 calc($form-check-tile-spacer * -0.5);
}

.radio-tile,
.check-tile {
    display: block;
    margin: 0 calc($form-check-tile-spacer * 0.5);

    label,
    input,
    input::after,
    .bullet,
    .bullet::after {
        cursor: pointer;
    }

    label {
        @extend .card;
        @extend .card-clickable;
        white-space: normal;
        min-width: $form-check-tile-min-width;
    }

    input:disabled,
    input[disabled] {
        & + label {
            &,
            .bullet,
            .bullet::after {
                cursor: not-allowed;
            }

            background-color: $input-disabled-bg;
            border-color: $input-disabled-bg;
        }
    }

    > input + label > .bullet {
        display: block;
        position: absolute;
        top: calc($card-spacer-y * 0.5);
        right: calc($card-spacer-x * 0.5);
    }

    .tile-icon,
    .tile-label {
        @extend .card-body;
        display: flex;
        justify-content: center;
    }

    .tile-label {
        padding-top: 0;
    }

    [type='checkbox']:checked,
    [type='checkbox']:indeterminate,
    [type='checkbox']:hover,
    [type='checkbox']:focus,
    [type='radio']:checked,
    [type='radio']:hover,
    [type='radio']:focus {
        &:not(:disabled) + label,
        &:not([disabled] + label) {
            border-color: $form-check-tile-border-color-focus;
            box-shadow: $input-focus-box-shadow;

            > .bullet {
                border-color: $form-check-tile-border-color-focus;
            }
        }
    }
}
