.input-group {
    &:not(.has-validation) {
        > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
        > .dropdown-toggle:nth-last-child(n + 3) {
            border-right-color: $transparent;
        }
    }

    &.has-validation {
        > :nth-last-child(n + 3):not(.dropdown-toggle):not(.dropdown-menu),
        > .dropdown-toggle:nth-last-child(n + 4) {
            border-right-color: $transparent;
        }
    }

    $validation-messages: '';
    @each $state in map-keys($form-validation-states) {
        $validation-messages: $validation-messages +
            ':not(.' +
            unquote($state) +
            '-tooltip)' +
            ':not(.' +
            unquote($state) +
            '-feedback)';
    }

    > :not(:first-child):not(.dropdown-menu)#{$validation-messages} {
        border-left-color: $transparent;
    }
}
