@mixin card-body(
    $bg,
    $color,
    $header-bg: null,
    $header-color: null,
    $border: null
) {
    @if $header-bg == null {
        $header-bg: $bg;
    }
    @if $header-color == null {
        $header-color: $color;
    }
    @if $border {
        border-color: $border;
    }

    &,
    > .card-body {
        background-color: $bg;
        color: $color;
    }

    > .card-header {
        background-color: $header-bg;
        color: $header-color;
    }
}
