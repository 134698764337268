$spacer: 1rem;
$spacers: (
    0: 0,
    1: $spacer * 0.5,
    2: $spacer,
    3: $spacer * 1.5,
    4: $spacer * 2,
    5: $spacer * 3,
);

// Links
$link-decoration: none;
$link-hover-decoration: underline;

// Grid
// Breakpoints as per Chrome standard
$grid-breakpoints: (
    xs: 0,
    // mobile
    sm: 425px,
    // tablet
    md: 768px,
    // laptop
    lg: 1024px,
    // desktop
    xl: 1440px,
    // big desktop
    xxl: 2560px,
);

// Grid Columns
$grid-gutter-width: 1rem;

// Borders
$border-radius: 0.6rem;
$border-radius-sm: 0.4rem;
$border-radius-lg: $border-radius;
$border-radius-xl: 2rem;

// Transition
$transition-duration-base: 0.2s;
$transition-curve-base: ease-in-out;
$transition-base: all $transition-duration-base $transition-curve-base;

// Typography
$font-family-sans-serif: 'Montserrat', system-ui, -apple-system, 'Segoe UI',
    Roboto, 'Helvetica Neue', Arial, 'Noto Sans', 'Liberation Sans', sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';

$font-size-root: 62.5%;
$font-size-root-small: 56.25%;
$font-size-root-extra-small: 50%;
$font-size-base: 1.4rem;

$font-size-breakpoints: (
    xs: $font-size-root-extra-small,
    sm: $font-size-root-extra-small,
    md: $font-size-root-small,
    lg: $font-size-root-small,
    xl: $font-size-root,
    xxl: $font-size-root,
);

$font-weight-sub: 500;
$font-weight-bold: 600;

$h1-font-size: $font-size-base * 2;
$h2-font-size: $font-size-base * 1.6;
$h3-font-size: $font-size-base * 1.5;
$h4-font-size: $font-size-base * 1.3;
$h5-font-size: $font-size-base * 1.15;
$h6-font-size: $font-size-base;

$headings-margin-bottom: 0;
$headings-font-weight: $font-weight-bold;
$headings-line-height: 1.5;

$h1-font-weight: $headings-font-weight;
$h2-font-weight: $headings-font-weight;
$h3-font-weight: $headings-font-weight;
$h4-font-weight: $font-weight-sub;
$h5-font-weight: $font-weight-bold;
$h6-font-weight: $font-weight-bold;

// Links
$link-font-weight: $font-weight-bold;

// Tables
$table-header-font-weight: $font-weight-sub;
$table-column-max-width: 45rem;
$table-column-max-width-sm: 30rem;

// hr
$hr-margin-y: 1.5rem;

// Buttons + Forms
$input-btn-padding-y: 0.6rem;
$input-btn-padding-x: 1.2rem;

$btn-font-weight: $font-weight-sub;

$btn-inline-max-width: 30rem;

$form-label-margin-bottom: 1rem;

$form-check-tile-spacer: $spacer;
$form-check-tile-min-width: 11.5rem;

// Nav + Tabs
$nav-link-padding-y: 1rem;
$nav-link-padding-x: 2.5rem;
$nav-link-font-weight: $font-weight-sub;

$nav-tabs-border-width: 0;
$nav-tabs-link-border-width: map-get($spacers, 1);
$nav-tabs-border-radius: 0;

$nav-side-tabs-link-padding-y: 1.5rem;
$nav-side-tabs-link-padding-x: 1rem;

// Navbar
$navbar-padding-y: 0.8rem;
$navbar-padding-x: 1.5rem;

$navbar-height: 6.4rem;

$navbar-brand-height: 3.6rem;
$navbar-brand-margin-end: $navbar-padding-x;

// Dropdowns
$dropdown-min-width: 22.5rem;
$dropdown-max-width: 30rem;
$dropdown-max-height: 50rem;

$dropdown-min-width-lg: 60rem;

$dropdown-padding-x: 0;
$dropdown-padding-y: $dropdown-padding-x;

$dropdown-item-padding-y: $spacer;
$dropdown-item-padding-x: $dropdown-item-padding-y;

$dropdown-header-padding: $dropdown-item-padding-y $dropdown-item-padding-x;
$dropdown-header-font-weight: $font-weight-bold;

// Toasts
$toast-max-width: 420px;
$toast-padding-x: $spacer * 1.5;
$toast-padding-y: $spacer;
$toast-font-size: $font-size-base;
$toast-border-width: 0;
$toast-z-index: 2000;

// Badges
$badge-font-size: 0.85em;
$badge-font-weight: $font-weight-sub;
$badge-padding-y: 0.3em;
$badge-padding-x: 0.5em;
$badge-border-width: 0.1em;

// Modals
$modal-dialog-margin-y-sm-up: 3.5rem;

// Alerts
$alert-margin-bottom: map-get($spacers, 1);

// List group
$list-group-item-padding-y: $spacer;
$list-group-item-padding-x: $list-group-item-padding-y;

// Cards
$card-spacer-y: $spacer;
$card-title-spacer-y: $card-spacer-y;
$card-cap-padding-y: $card-spacer-y;

$card-img-cover-height: 23rem;

// Accordion

$accordion-icon-width: $font-size-base;

// Breadcrumbs
$breadcrumb-margin-bottom: 0;
$breadcrumb-divider-color: inherit;
$breadcrumb-active-color: inherit;

// Spinners
$spinner-width: 4rem;

// Offcanvas
$offcanvas-horizontal-width: var(--offcanvas-horizontal-width);
$offcanvas-horizontal-widths: (
    'sm': 300px,
    'md': 500px,
    'lg': 800px,
    'xl': 1140px,
);
$offcanvas-border-width: 0px;

@import 'bootstrap/scss/variables';

// Dashboard grid
$dashboard-filters-column-width: 35rem;

$dashboard-collapsible-column-width: 60rem;
$dashboard-collapsible-column-width-collapsed: 7rem;

// Height utility classes
$utility-heights: (
    0: 0%,
    10: 10%,
    25: 25%,
    50: 50%,
    75: 75%,
    90: 90%,
    100: 100%,
    auto: auto,
);

$utility-opacities: (
    25: 0.25,
    50: 0.5,
    75: 0.75,
    100: 1,
);

// Input switch
$input-switch-container-height: 1.7rem;
$input-switch-container-width: 3rem;
$input-switch-container-border-width: map-get($border-widths, 2);

$input-switch-size: 1.1rem;
$input-switch-top: 0.1rem;
$input-switch-left: 0.2rem;
$input-switch-active-left: 1.4rem;

// Divider
$divider-width: map-get($border-widths, 1);
$divider-length: $spacer * 2;
$divider-length-short: $spacer * 1.5;
$divider-offset-sm: $spacer;
$divider-offset-md: $spacer * 2;
$divider-offset-lg: $spacer * 4;

// Icons
$icon-sizes: (
    'xxs': 1rem,
    'xs': 1.2rem,
    'sm': 1.4rem,
    'md': 1.6rem,
    'lg': 2.4rem,
    'xl': 3.4rem,
    'xxl': 6rem,
    'xxxl': 10rem,
);

$icon-container-sizes: (
    'xxs': 1.6rem,
    'xs': 2rem,
    'sm': 2.6rem,
    'md': 3.6rem,
    'lg': 4.6rem,
    'xl': 7rem,
    'xxl': 10rem,
    'xxxl': 14rem,
);

// Avatars
$avatar-font-weight: 500;

$avatar-sizes: $icon-container-sizes;

$avatar-alt-initials-sizes: (
    'xs': 0.8rem,
    'sm': 1rem,
    'md': 1.4rem,
    'lg': 1.8rem,
    'xl': 2.8rem,
    'xxl': 4rem,
    'xxxl': 6rem,
);

$avatar-alt-text-sizes: (
    'xs': $font-size-base * 0.35,
    'sm': $font-size-base * 0.42,
    'md': $font-size-base * 0.57,
    'lg': $font-size-base,
    'xl': $font-size-base,
    'xxl': $font-size-base,
    'xxxl': $font-size-base,
);

$avatar-group-offset: 0.25;
$avatar-group-border-width: map-get($border-widths, 2);
$avatar-group-limit: 2;

$avatar-info-min-width: 30rem;

// Timeline
$timeline-cell-spacer-x: map-get($spacers, 1);
$timeline-cell-spacer-y: $timeline-cell-spacer-x;

$timeline-line-width: map-get($border-widths, 2);

// Widgets
$widget-spacer-y: 1.5rem;
$widget-spacer-x: 2rem;
$widget-title-spacer-y: $widget-spacer-y * 0.5;
$widget-border-width: 0;
$widget-border-radius: 0;
$widget-box-shadow: null;
$widget-inner-border-radius: subtract(
    $widget-border-radius,
    $widget-border-width
);
$widget-cap-padding-y: $widget-spacer-y;
$widget-cap-padding-x: $widget-spacer-x;
$widget-cap-border-width: map-get($border-widths, 1);
$widget-heights: (
    xs: 26rem,
    sm: 33rem,
    md: 55rem,
    // must show 6 rows worth of data before scrolling
    4: 65rem,
    5: 80rem,
    unset: unset,
);
$widget-height: map-get($widget-heights, unset);
$widget-img-overlay-padding: $spacer;
$widget-group-margin: $grid-gutter-width * 0.5;

// Scrollbar
$scrollbar-width: 1.4rem;
$scrollbar-border-radius: 2rem;
$scrollbar-border-width: 0.5rem;

// Date/time picker
$datepicker-padding-x: $spacer;
$datepicker-padding-y: $datepicker-padding-x;
$datepicker-padding: $datepicker-padding-y $datepicker-padding-x;

$datepicker-navigation-flex-basis: 11rem;

$datepicker-days-margin-top: map-get($spacers, 1);

$datepicker-day-margin: 0;
$datepicker-day-size: 3.4rem;
$datepicker-day-padding: 0.7rem;

$datepicker-months-margin-top: $spacer;

$datepicker-month-gutter: $spacer;

$timepicker-gutter: map-get($spacers, 4);
$timepicker-chevron-button-padding: 1px $btn-padding-x;

// Offcanvas
$zindex-offcanvas-backdrop: $zindex-offcanvas;

// Loaders
$skeleton-loader-margin-y: 2px;

$dot-loader-size: 1rem;
$dot-loader-gap: calc($dot-loader-size * 0.5);
$dot-loader-duration: 0.66s;
$dot-loader-margin-y: 0.2rem;
