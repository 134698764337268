//
// Base styles
//

.widget {
    @extend .card;
    background-color: $widget-bg;
    border: $widget-border-width solid $widget-border-color;
    @include border-radius($widget-border-radius);
    @include box-shadow($widget-box-shadow);
    @include scrollbar($widget-bg);

    > .list-group {
        &:first-child {
            @include border-top-radius($widget-inner-border-radius);
        }

        &:last-child {
            @include border-bottom-radius($widget-inner-border-radius);
        }
    }

    @each $height, $value in $widget-heights {
        &.widget-#{$height} {
            height: $value;
        }
    }
}

.widget-body {
    @extend .card-body;
    padding: $widget-spacer-y $widget-spacer-x;
    color: $widget-color;
}

// widget bodies inside of widget bodies will not have padding, unless speficially stated
.widget-body .widget-body {
    padding: 0;
}

.widget-body .widget-retain-padding .widget-body {
    padding: $widget-spacer-y $widget-spacer-x;

    .widget-body {
        padding: 0;
    }
}

.widget-padding {
    padding: $widget-spacer-y $widget-spacer-x;
}

.widget-title {
    @extend .card-title;
    margin-bottom: $widget-title-spacer-y;
}

.widget-subtitle {
    @extend .card-subtitle;
    margin-top: -$widget-title-spacer-y * 0.5;
}

.widget-text {
    @extend .card-text;
}

.widget-link {
    @extend .card-link;

    + .widget-link {
        margin-left: $widget-spacer-x;
    }
}

//
// Optional textual caps
//

.widget-header {
    @extend .card-header;
    display: flex;
    padding: $widget-cap-padding-y $widget-cap-padding-x;
    color: $widget-cap-color;
    background-color: $widget-cap-bg;
    border-bottom: $widget-cap-border;

    &:first-child {
        @include border-radius(
            $widget-inner-border-radius $widget-inner-border-radius 0 0
        );
    }
}

.widget-footer {
    @extend .card-footer;
    padding: $widget-cap-padding-y $widget-cap-padding-x;
    color: $widget-cap-color;
    background-color: $widget-cap-bg;
    border-top: $widget-cap-border;

    &:last-child {
        @include border-radius(
            0 0 $widget-inner-border-radius $widget-inner-border-radius
        );
    }
}
