input[type='date'],
input[type='datetime-local'],
input[type='time'] {
    position: relative;

    &::after {
        display: block;
        @include fa-icon();
        position: absolute;
        top: 50%;
        right: $input-padding-x;
        transform: translateY(-50%);
        font-size: inherit;
        color: inherit;
        z-index: 0;
    }

    &::-webkit-calendar-picker-indicator {
        opacity: 0;
        z-index: 1;
    }
}

input[type='date']::after,
input[type='datetime-local']::after {
    content: '\f133';
}

input[type='time']::after {
    content: '\f017';
}
