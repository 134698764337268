// spacing
.gap-0 {
    gap: 0;
}

.row-gap-0 {
    row-gap: 0;
}

// position
.position-initial {
    position: initial;
}

// text effects
.block-truncate {
    height: 1.4rem;

    p:first-child {
        display: block;
        margin: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    p {
        display: none;
    }
}

// cursors
.cursor-help {
    cursor: help;
}

// value / info card layout
.info-card-container {
    --gap: 0.5rem;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: calc(var(--gap) * -1);

    > * {
        margin: var(--gap);
    }
}

// list spacing
.data-list-content {
    display: grid;
    grid-template-columns: minmax(100%, 1fr);
    grid-template-rows: auto;
    grid-gap: 1rem;
}

.text-pre-line {
    white-space: pre-line;
}

.stack-icon {
    margin: -0.2em -0.65em;
}

.min-w-0 {
    min-width: 0;
}
