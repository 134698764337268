@use 'wdx';

editor {
    .tox-tinymce {
        border: wdx.$input-border-width solid wdx.$input-border-color;
        border-radius: wdx.$input-border-radius;
        color: wdx.$input-color;
    }

    .tox {
        .tox-toolbar-overlord,
        .tox-edit-area__iframe,
        .tox-edit-area__iframe .mce-content-body,
        .tox-sidebar,
        .tox-statusbar {
            background-color: wdx.$input-bg;
            color: wdx.$input-color;
        }

        .tox-toolbar,
        .tox-editor-header,
        .tox-toolbar-overlord > .tox-toolbar__primary,
        &:not(.tiny-mce-inline) .tox-editor-header {
            background-color: wdx.$body-bg-muted;
            color: wdx.$body-color;
        }

        .tox-editor-header,
        &:not(.tiny-mce-inline) .tox-editor-header {
            box-shadow: none;
            border-bottom: wdx.$input-border-width solid wdx.$input-border-color;
        }

        .tox-tbtn,
        .tox-tbtn:hover,
        .tox-tbtn--enabled {
            color: wdx.$input-color;

            svg {
                fill: wdx.$input-color;
            }
        }

        .tox-tbtn {
            background-color: wdx.$input-bg;
        }

        .tox-tbtn:hover,
        .tox-tbtn--enabled {
            background-color: wdx.$body-bg-strong;
        }

        .tox-tbtn--disabled svg,
        .tox-tbtn--disabled:hover svg,
        .tox-tbtn:disabled svg,
        .tox-tbtn:disabled:hover svg {
            fill: wdx.$input-color;
        }

        .tox-split-button.tox-tbtn--disabled,
        .tox-tbtn--disabled,
        .tox-tbtn:disabled {
            &,
            &:focus,
            &:hover,
            .tox-tbtn:focus,
            .tox-tbtn:hover,
            svg {
                background: wdx.$input-bg;
            }
        }
    }
}
