$bp: lg;

$filters-column-width: 35rem;
$collapsible-column-width: 60rem;
$collapsible-column-width-collapsed: 7rem;

.dashboard-container {
    height: 100%;
    width: 100%;
    overflow: hidden;
    --#{$variable-prefix}columns: 2;

    @include media-breakpoint-down($bp) {
        --#{$variable-prefix}columns: 1;
    }
}

.dashboard-grid {
    grid-template-columns: 1fr 2fr;
    overflow: hidden;

    @include media-breakpoint-down($bp) {
        grid-template-columns: 100%;
        overflow: auto;
    }

    &:has(.dashboard-grid-scroll-column) {
        @include media-breakpoint-down($bp) {
            gap: 0;
        }
    }
}

.dashboard-grid-filters {
    grid-template-columns: min-content 1fr;
    overflow: hidden;

    .filters-column,
    .filters-column-fixed {
        display: block;
        height: 100%;
        width: $dashboard-filters-column-width;
        overflow: hidden;
        @include transition();
    }

    &.collapsed {
        gap: 0px;

        .filters-column {
            width: 0px;
            white-space: nowrap;
            overflow: hidden;
        }
    }

    @include media-breakpoint-down($bp) {
        grid-template-columns: 100%;

        .filters-column {
            display: none;
        }
    }
}

.dashboard-grid-collapsible {
    grid-template-columns: min-content 1fr;
    overflow: hidden;

    .collapsible-col-body {
        display: block;
        height: 100%;
        width: $dashboard-collapsible-column-width;
        overflow: hidden;
        @include transition();
    }

    .collapsible-col {
        overflow: hidden;

        &.collapsed .collapsible-col-body {
            width: $dashboard-collapsible-column-width-collapsed;
        }
    }

    @include media-breakpoint-down($bp) {
        grid-template-columns: 1fr 2fr;

        .collapsible-col-body {
            width: 100%;
        }
    }
}

.dashboard-grid-scroll-column {
    display: flex;
    flex-direction: column;
    gap: $grid-gutter-width;

    @include media-breakpoint-up($bp) {
        max-height: 100%;
        overflow: auto;
    }
}

.dashboard-tab-content {
    display: block;
    height: 100%;
    overflow: hidden;
    padding-top: map-get($spacers, 1);
}
