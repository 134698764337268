@mixin table-variant($color, $background) {
    .table-#{$color} {
        // This is a very basic implementation of what you could do with these table variants
        // Right now it relies on the primary theme colours, and uses the generated hover state
        // colours to create striped / hover etc. styles but there's no reason why you couldn't
        // create 'bg' and 'bold' versions of the theme colours in order to more extensively
        // customise the tables

        $clr: var(--#{$variable-prefix}#{$color}-contrast);
        $hover-bg: var(--#{$variable-prefix}#{$color}-hover);
        $hover-color: var(--#{$variable-prefix}#{$color}-hover-contrast);
        $striped-bg: var(--#{$variable-prefix}#{$color}-hover);
        $striped-color: var(--#{$variable-prefix}#{$color}-hover-contrast);
        $active-bg: var(--#{$variable-prefix}#{$color});
        $active-color: var(--#{$variable-prefix}#{$color}-contrast);
        $accent-bg: var(--#{$variable-prefix}#{$color});
        $accent-color: var(--#{$variable-prefix}#{$color}-contrast);

        --#{$variable-prefix}table-bg: #{$background};
        --#{$variable-prefix}table-striped-bg: #{$striped-bg};
        --#{$variable-prefix}table-striped-color: #{$striped-color};
        --#{$variable-prefix}table-active-bg: #{$active-bg};
        --#{$variable-prefix}table-active-color: #{$active-color};
        --#{$variable-prefix}table-accent-bg: #{$accent-bg};
        --#{$variable-prefix}table-accent-color: #{$accent-color};
        --#{$variable-prefix}table-hover-bg: #{$hover-bg};
        --#{$variable-prefix}table-hover-color: #{$hover-color};

        color: $clr;
        border-color: $clr;
    }
}
