@mixin rgb-vars($color, $r, $g, $b, $theme-prefix: $variable-prefix) {
    $var: #{$theme-prefix}#{$color};
    --#{$var}-red: #{$r};
    --#{$var}-green: #{$g};
    --#{$var}-blue: #{$b};
}

@mixin set-rgb-variable($color, $theme-prefix: $variable-prefix) {
    $c: #{$theme-prefix}#{$color};
    --#{$c}-rgb: #{var(--#{$c}-red)}, #{var(--#{$c}-green)},
        #{var(--#{$c}-blue)};
    --#{$c}: rgb(#{var(--#{$c}-rgb)});
}

@mixin set-contrast-variables(
    $color,
    $suffix,
    $theme-prefix: $variable-prefix
) {
    $c: #{$theme-prefix}#{$color}#{$suffix};
    --#{$c}-contrast-index: calc(
        (
                (
                        (
                                (var(--#{$c}-red) * 299) +
                                    (var(--#{$c}-green) * 587) +
                                    (var(--#{$c}-blue) * 114)
                            ) / 1000
                    ) - 128
            ) * -1000
    );
    --#{$c}-contrast-rgb: #{var(--#{$c}-contrast-index)},
        #{var(--#{$c}-contrast-index)}, #{var(--#{$c}-contrast-index)};
    --#{$c}-contrast: rgb(#{var(--#{$c}-contrast-rgb)});
}

@mixin set-theme($color, $theme-prefix: $variable-prefix) {
    $c: #{$theme-prefix}#{$color};
    $h: var(--#{$variable-prefix}hover-shade-modifier);
    @include set-rgb-variable($color, $theme-prefix);
    @include set-contrast-variables($color, null, $theme-prefix);
    // hover
    --#{$c}-hover-red: calc(#{var(--#{$c}-red)} * #{$h});
    --#{$c}-hover-green: calc(#{var(--#{$c}-green)} * #{$h});
    --#{$c}-hover-blue: calc(#{var(--#{$c}-blue)} * #{$h});
    --#{$c}-hover: rgb(
        var(--#{$c}-hover-red),
        var(--#{$c}-hover-green),
        var(--#{$c}-hover-blue)
    );
    // hover contrast
    @include set-contrast-variables($color, -hover, $theme-prefix);
}

@mixin set-extended-theme($color, $contrast, $theme-prefix: $variable-prefix) {
    $c: #{$theme-prefix}#{$color};
    @include set-rgb-variable($color, $theme-prefix);
    --#{$c}-contrast-rgb: #{var(--#{$theme-prefix}#{$contrast}-red)},
        #{var(--#{$theme-prefix}#{$contrast}-green)},
        #{var(--#{$theme-prefix}#{$contrast}-blue)};
    --#{$c}-contrast: rgb(var(--#{$c}-contrast-rgb));
}
