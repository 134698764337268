@use 'wdx';

ngx-dropzone {
    border-color: wdx.$border-color !important;
}

ngx-dropzone-preview {
    overflow: hidden;
}

ngx-dropzone-label {
    max-width: 100%;
}

ngx-dropzone,
ngx-dropzone-label {
    background-color: wdx.$body-bg-muted !important;
    color: wdx.$body-color !important;
}
