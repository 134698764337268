@import 'bootstrap/scss/offcanvas';

$default-width: md;

.offcanvas {
    --offcanvas-horizontal-width: #{map-get(
            $offcanvas-horizontal-widths,
            $default-width
        )};

    @each $width, $value in $offcanvas-horizontal-widths {
        &-#{$width} {
            --offcanvas-horizontal-width: #{$value};
        }
    }

    &.show,
    &.showing,
    &.hiding {
        visibility: visible;
    }

    &.show,
    &.showing {
        &:not(.hiding) {
            transform: none;
        }
    }
}

.offcanvas.hiding {
    &.offcanvas-top {
        transform: translateY(-100%);
    }
    &.offcanvas-start {
        transform: translateX(-100%);
    }
    &.offcanvas-bottom {
        transform: translateY(100%);
    }
    &.offcanvas-end {
        transform: translateX(100%);
    }
}

.offcanvas-body {
    @include scrollbar($offcanvas-bg-color);
}

.offcanvas .form {
    padding: $offcanvas-padding-y $offcanvas-padding-x;
}
