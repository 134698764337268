@import '../../../../node_modules/bootstrap/scss/tooltip';

ngb-tooltip-window {
    &.show {
        opacity: 1 !important;
    }

    &.tooltip-sm {
        max-width: 8rem;
    }

    .tooltip-inner {
        padding: 0.5rem 1rem;
        background-color: $body-bg;
        color: $body-color;
        border-radius: $border-radius-sm;
        box-shadow: $box-shadow;
    }

    &.bs-tooltip-top .tooltip-arrow::before {
        border-top-color: $body-bg !important;
    }

    &.bs-tooltip-bottom .tooltip-arrow::before {
        border-bottom-color: $body-bg !important;
    }

    &.bs-tooltip-start .tooltip-arrow::before {
        border-left-color: $body-bg !important;
    }

    &.bs-tooltip-end .tooltip-arrow::before {
        border-right-color: $body-bg !important;
    }
}
