@use 'wdx';

mention-list {
    ul.dropdown-menu.mention-menu {
        min-width: wdx.$dropdown-min-width;
        padding: wdx.$dropdown-padding-y wdx.$dropdown-padding-x;
        margin: 0;
        font-size: wdx.$dropdown-font-size;
        color: wdx.$dropdown-color;
        background-color: wdx.$dropdown-bg;
        border: wdx.$dropdown-border-width solid wdx.$dropdown-border-color;
        border-radius: wdx.$dropdown-border-radius;

        a.mention-item {
            padding: wdx.$dropdown-item-padding-y wdx.$dropdown-item-padding-x;
            line-height: wdx.$line-height-base;
            font-weight: wdx.$font-weight-base;
            color: wdx.$dropdown-link-color;
            cursor: pointer;

            &:hover {
                color: wdx.$dropdown-link-hover-color;
                background-color: wdx.$dropdown-link-hover-bg;
            }
        }

        li.mention-active > a.mention-item {
            color: wdx.$dropdown-link-active-color;
            background-color: wdx.$dropdown-link-active-bg;
        }
    }
}
