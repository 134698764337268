@use 'wdx';

$modal-header-bg: wdx.$primary;
$modal-header-color: wdx.$primary-contrast;

$modal-header-height: wdx.$navbar-height;

.custom-fade {
    @include wdx.transition(wdx.$modal-transition);

    .modal-dialog {
        @include wdx.transition(wdx.$modal-transition);
    }
}

@keyframes modalIn {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(-100%);
    }
}

.modal-dialog-transition {
    animation-name: modalIn;
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
}

ngb-modal-backdrop:not(.modal-backdrop--ngb),
ngb-modal-backdrop:not(.modal-backdrop--ngb).modal-backdrop,
.modal-backdrop:not(.modal-backdrop--ngb) {
    &,
    &.show,
    &.custom-fade,
    &:not(.fade-in) {
        opacity: 0;
    }

    &.fade-in {
        opacity: wdx.$modal-backdrop-opacity;
    }
}

.modal:not(.modal-window--ngb),
.modal:not(.modal-window--ngb).show,
.modal.custom-fade.show {
    .modal-dialog {
        transform: translateX(0%);
    }
}

.modal:not(.modal-window--ngb).show.fade-in,
.modal.custom-fade.fade-in.show {
    &,
    &.right {
        .modal-dialog {
            transform: translateX(-100%);
        }
    }

    &.left {
        .modal-dialog {
            transform: translateX(100%);
        }
    }
}

.modal:not(.modal-window--ngb).show {
    .modal-content {
        height: 100%;
        border: 0;
        border-radius: 0;
    }
}

.modal:not(.modal-window--ngb).show {
    &,
    &.right {
        .modal-dialog {
            height: 100vh;
            max-width: 100vw;
            min-width: unset;
            margin: 0;
            position: fixed;
            top: 0;
            right: unset;
            left: 100%;

            &:not(.modal-xs) {
                @include wdx.media-breakpoint-down(sm) {
                    min-width: 100vw;
                }
            }
        }
    }

    &.left {
        .modal-dialog {
            left: unset;
            right: 100%;
        }
    }

    .modal-content {
        height: 100%;
        border: 0;
        border-radius: 0;
    }

    .modal-padding {
        padding: wdx.$modal-inner-padding;

        &--banner {
            padding-top: wdx.$modal-inner-padding;
            padding-bottom: wdx.$modal-inner-padding;
        }

        &--body {
            padding-top: wdx.$modal-inner-padding;
        }
    }
}

@include wdx.media-breakpoint-up(xs) {
    .modal-xs,
    .modal-xs.modal-dialog {
        width: wdx.$modal-sm;
    }
}

@include wdx.media-breakpoint-up(sm) {
    .modal-dialog {
        width: wdx.$modal-md;
    }
    .modal-sm {
        width: wdx.$modal-sm;
    }
}

@include wdx.media-breakpoint-up(lg) {
    .modal-lg,
    .modal-xl {
        width: wdx.$modal-lg;
    }
}

@include wdx.media-breakpoint-up(xl) {
    .modal-xl {
        width: wdx.$modal-xl;
    }
}

.modal-full {
    width: calc(100vw - #{wdx.$modal-dialog-margin-y-sm-up});
}

.modal-header {
    background-color: $modal-header-bg;
    color: $modal-header-color;
    display: flex;
    align-items: center;
    height: $modal-header-height;
    border: none;
    border-radius: 0;

    .close {
        @extend .btn;
        @extend .btn-primary;
        border-radius: 100%;
    }
}

.modal-banner {
    padding: wdx.$modal-inner-padding;
    background-color: wdx.$primary-light;
    color: wdx.$primary-light-contrast;
}

.modal-body {
    height: calc(100vh - #{$modal-header-height});
    overflow: hidden;
    padding: 0;
    @include wdx.scrollbar(wdx.$modal-content-bg);

    .preformatted {
        white-space: pre-wrap;
        word-wrap: break-word;
    }
}

.center-modal {
    @extend .absolute-center;

    &__content {
        background-color: wdx.$modal-content-bg;
        color: wdx.$modal-content-color;
        padding: wdx.$widget-spacer-y wdx.$widget-spacer-x;
        transform: translateY(2rem);
        opacity: 0;
        max-width: wdx.$modal-md;
        min-width: wdx.$modal-sm;
        @include wdx.transition();
        @include wdx.border-radius();
    }
}

.fade-in {
    .center-modal {
        &__content {
            opacity: 1;
            transform: translateY(0rem);
        }
    }
}

.modal-dialog-fullscreen {
    margin: auto;
    width: 100%;
    max-width: 2440px;
    padding: 1rem;
    z-index: 9999999;

    &--no-pd {
        padding: 0;
    }
}

.modal-dialog-xl-fullscreen {
    margin: auto;
    width: 100%;
}

.modal-dialog-centered:not(.modal-dialog-centred-xs) {
    height: 100%;
    max-width: 1200px;
    max-height: 600px;
    padding: 1rem;
    .modal-content {
        border: none;
        height: 100%;
    }
}

@include wdx.media-breakpoint-up(md) {
    .modal-dialog-centered:not(.modal-dialog-centred-xs) {
        width: 75%;
        .modal-content {
            height: 75%;
        }
    }
}

@include wdx.media-breakpoint-up(lg) {
    .modal-dialog-centered:not(.modal-dialog-centred-xs) {
        width: 50%;
    }
}

@include wdx.media-breakpoint-up(xl) {
    .modal-dialog-fullscreen {
        padding: 3rem;

        &--no-pd {
            padding: 0;
        }
    }
}
