// Lifecycle stage
$lifecycle-stage-colors: (
    'advocate': #1abc9c,
    'client': #2ecc71,
    'disqualified': #253038,
    'engaged': #3498db,
    'flight-risk': #9b59b6,
    'left': #34495e,
    'onboarding': #f1c40f,
    'pitching': #e67e22,
    'switched': #7f8c8d,
    'target': #e74c3c,
    'unwinding': #fd79a8,
    'potentiallywithoutcontact': #ffa600,
    'withoutcontact': #ffa600,
    'dormant': #f14a4b,
    'pendingdeletion': #ff4500,
);

$lifecycle-stage-colors-contrast: (
    'advocate': $black,
    'client': $black,
    'disqualified': $white,
    'engaged': $white,
    'flight-risk': $white,
    'left': $white,
    'onboarding': $black,
    'pitching': $white,
    'switched': $white,
    'target': $white,
    'unwinding': $black,
    'potentiallywithoutcontact': $black,
    'withoutcontact': $black,
    'dormant': $black,
    'pendingdeletion': $white,
);

$lifecycle-stage-colors-light: (
    'advocate': #90f0dd,
    'client': #b5eece,
    'disqualified': #88a0b2,
    'engaged': #c4e1f5,
    'flight-risk': #e2cfea,
    'left': #829eba,
    'onboarding': #faebaf,
    'pitching': #f7d7bb,
    'switched': #ecf0f1,
    'target': #fad7d3,
    'unwinding': #fecdde,
    'potentiallywithoutcontact': #f9dda8,
    'withoutcontact': #f9dda8,
    'dormant': #f39d9d,
    'pendingdeletion': #ff5349,
);

// File type
$file-type-colors: (
    'pdf': #ff0000,
    'excel': #1f7246,
    'image': #1a4a8b,
    'powerpoint': #d04626,
    'word': #2b5797,
);
