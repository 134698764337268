// bg and text color classes
@each $color in map-keys($theme-colors-light) {
    @include utility-bg-class(#{$color}-light);
    @include utility-color-class(#{$color}-light);
}

@each $color in map-keys($theme-colors-dark) {
    @include utility-bg-class(#{$color}-dark);
    @include utility-color-class(#{$color}-dark);
}

@each $color in map-keys($theme-colors-contrast) {
    @include utility-color-class(#{$color}-contrast);
}

@each $color in map-keys($theme-colors-light-contrast) {
    @include utility-color-class(#{$color}-light-contrast);
}

@each $color in map-keys($theme-colors-dark-contrast) {
    @include utility-color-class(#{$color}-dark-contrast);
}

// body classes
@include utility-bg-class(body, body-bg);
@each $variable in (muted, strong) {
    @include utility-bg-class(#{$variable}, body-bg-#{$variable});
    @include utility-color-class(#{$variable}, body-color-#{$variable});
}

// active component classes
.bg-active-component {
    background-color: $component-active-bg;
}
.text-active-component {
    color: $component-active-color;
}
.active-component {
    background-color: $component-active-bg;
    color: $component-active-color;
}

// border classes
@each $color, $value in $theme-colors {
    .border-#{$color} {
        border-color: #{$value} !important;
    }
}

// system color classes
@each $color, $value in $colors {
    .bg-#{$color} {
        background-color: $value;
    }
    .text-#{$color} {
        color: $value;
    }
    .text-#{$color}-contrast {
        color: map-get($colors-contrast, $color);
    }
}

// type classes
@include utility-color-class(muted, body-color-muted);

// flex
.flex-0 {
    flex: 0 !important;
}

.flex-1 {
    flex: 1 !important;
}

// heights
@each $height, $value in $utility-heights {
    .h-#{$height} {
        height: $value !important;
    }
    .min-h-#{$height} {
        min-height: $value !important;
    }
    .max-h-#{$height} {
        max-height: $value !important;
    }
}

// positioning
.absolute-center {
    @include absolute-center();
}

// cursor
.pointer {
    cursor: pointer;
}

// scrolling
.scroll-smooth {
    position: relative;
    scroll-behavior: smooth;
}

// hide when empty
.hide-when-empty:empty {
    display: none;
}

* > p:last-of-type {
    margin-bottom: 0;
}

@each $label, $value in $utility-opacities {
    .bg-opacity-#{$label} {
        --bs-bg-opacity: #{$value};
    }
}
