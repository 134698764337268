@each $stage, $value in $lifecycle-stage-colors {
    .lifecycle-stage-#{$stage} {
        background-color: $value !important;
        border-color: $value !important;
        color: map-get($lifecycle-stage-colors-contrast, $stage) !important;
    }
}

@each $stage, $value in $lifecycle-stage-colors-light {
    .lifecycle-stage-#{$stage}-light {
        background-color: $value !important;
        border-color: $value !important;
        color: $black !important;
    }
}
