@import 'bootstrap/scss/popover';

.popover-dropdown-menu,
.popover-no-padding {
    .popover-body {
        padding: 0;
    }
}

.popover-avatar {
    max-width: 500px;
}

.popover-body p:last-of-type {
    padding: 0;
    margin: 0;
}
