@import 'bootstrap/scss/type';

%heading {
    margin-top: 0; // 1
    margin-bottom: $headings-margin-bottom;
    font-family: $headings-font-family;
    font-style: $headings-font-style;
    font-weight: $headings-font-weight;
    line-height: $headings-line-height;
    color: $headings-color;
}

h1 {
    @extend %heading;
    @include font-size($h1-font-size);
    font-weight: $h1-font-weight;
}

h2 {
    @extend %heading;
    @include font-size($h2-font-size);
    font-weight: $h2-font-weight;
}

h3 {
    @extend %heading;
    @include font-size($h3-font-size);
    font-weight: $h3-font-weight;
}

h4 {
    @extend %heading;
    @include font-size($h4-font-size);
    font-weight: $h4-font-weight;
}

h5 {
    @extend %heading;
    @include font-size($h5-font-size);
    font-weight: $h5-font-weight;
}

h6 {
    @extend %heading;
    @include font-size($h6-font-size);
    font-weight: $h6-font-weight;
}

a {
    @include transition($btn-transition);
}

.inline-bullet::after {
    content: '\25CF';
    color: $body-color-muted;
    opacity: 50%;
    margin-left: map-get($spacers, 1);
    margin-right: map-get($spacers, 1);
}
