@import 'bootstrap/scss/carousel';

.carousel-inner,
.carousel-item {
    height: 100%;
}

.carousel-control-prev,
.carousel-control-next {
    max-width: 10rem;
    z-index: 100;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
    background: $transparent;
    @include fa-icon();
    color: inherit;
    @include transition();

    &::after {
        font-size: $carousel-control-icon-width;
    }
}

.carousel-control-prev-icon::after {
    content: '\f053';
}

.carousel-control-next-icon::after {
    content: '\f054';
}
