// These variables reassign all of the static SCSS variables to CSS variables, and then ensure that each relevant property is consistently assigned to a color

// Color system
$white: var(--#{$variable-prefix}white);
$gray-100: var(--#{$variable-prefix}gray-100);
$gray-200: var(--#{$variable-prefix}gray-200);
$gray-300: var(--#{$variable-prefix}gray-300);
$gray-400: var(--#{$variable-prefix}gray-400);
$gray-500: var(--#{$variable-prefix}gray-500);
$gray-600: var(--#{$variable-prefix}gray-600);
$gray-700: var(--#{$variable-prefix}gray-700);
$gray-800: var(--#{$variable-prefix}gray-800);
$gray-900: var(--#{$variable-prefix}gray-900);
$black: var(--#{$variable-prefix}black);

$white-rgb: var(--#{$variable-prefix}white-rgb);
$black-rgb: var(--#{$variable-prefix}black-rgb);

$grays: (
    '100': $gray-100,
    '200': $gray-200,
    '300': $gray-300,
    '400': $gray-400,
    '500': $gray-500,
    '600': $gray-600,
    '700': $gray-700,
    '800': $gray-800,
    '900': $gray-900,
);

$blue: var(--#{$variable-prefix}blue);
$indigo: var(--#{$variable-prefix}indigo);
$purple: var(--#{$variable-prefix}purple);
$pink: var(--#{$variable-prefix}pink);
$red: var(--#{$variable-prefix}red);
$orange: var(--#{$variable-prefix}orange);
$yellow: var(--#{$variable-prefix}yellow);
$green: var(--#{$variable-prefix}green);
$teal: var(--#{$variable-prefix}teal);
$cyan: var(--#{$variable-prefix}cyan);
$gray: var(--#{$variable-prefix}gray);

$blue-contrast: var(--#{$variable-prefix}blue-contrast);
$indigo-contrast: var(--#{$variable-prefix}indigo-contrast);
$purple-contrast: var(--#{$variable-prefix}purple-contrast);
$pink-contrast: var(--#{$variable-prefix}pink-contrast);
$red-contrast: var(--#{$variable-prefix}red-contrast);
$orange-contrast: var(--#{$variable-prefix}orange-contrast);
$yellow-contrast: var(--#{$variable-prefix}yellow-contrast);
$green-contrast: var(--#{$variable-prefix}green-contrast);
$teal-contrast: var(--#{$variable-prefix}teal-contrast);
$cyan-contrast: var(--#{$variable-prefix}cyan-contrast);
$gray-contrast: var(--#{$variable-prefix}gray-contrast);

$colors: (
    'blue': $blue,
    'indigo': $indigo,
    'purple': $purple,
    'pink': $pink,
    'red': $red,
    'orange': $orange,
    'yellow': $yellow,
    'green': $green,
    'teal': $teal,
    'cyan': $cyan,
    'gray': $gray,
);

$colors-contrast: (
    'blue': $blue-contrast,
    'indigo': $indigo-contrast,
    'purple': $purple-contrast,
    'pink': $pink-contrast,
    'red': $red-contrast,
    'orange': $orange-contrast,
    'yellow': $yellow-contrast,
    'green': $green-contrast,
    'teal': $teal-contrast,
    'cyan': $cyan-contrast,
    'gray': $gray-contrast,
);

$primary: var(--#{$variable-prefix}primary);
$secondary: var(--#{$variable-prefix}secondary);
$success: var(--#{$variable-prefix}success);
$info: var(--#{$variable-prefix}info);
$warning: var(--#{$variable-prefix}warning);
$danger: var(--#{$variable-prefix}danger);
$light: var(--#{$variable-prefix}light);
$dark: var(--#{$variable-prefix}dark);

$primary-rgb: var(--#{$variable-prefix}primary-rgb);
$secondary-rgb: var(--#{$variable-prefix}secondary-rgb);
$success-rgb: var(--#{$variable-prefix}success-rgb);
$info-rgb: var(--#{$variable-prefix}info-rgb);
$warning-rgb: var(--#{$variable-prefix}warning-rgb);
$danger-rgb: var(--#{$variable-prefix}danger-rgb);
$light-rgb: var(--#{$variable-prefix}primary-rgb);
$dark-rgb: var(--#{$variable-prefix}dark-rgb);

$primary-contrast: var(--#{$variable-prefix}primary-contrast);
$secondary-contrast: var(--#{$variable-prefix}secondary-contrast);
$success-contrast: var(--#{$variable-prefix}success-contrast);
$info-contrast: var(--#{$variable-prefix}info-contrast);
$warning-contrast: var(--#{$variable-prefix}warning-contrast);
$danger-contrast: var(--#{$variable-prefix}danger-contrast);
$light-contrast: var(--#{$variable-prefix}light-contrast);
$dark-contrast: var(--#{$variable-prefix}dark-contrast);

$primary-hover: var(--#{$variable-prefix}primary-hover);
$secondary-hover: var(--#{$variable-prefix}secondary-hover);
$success-hover: var(--#{$variable-prefix}success-hover);
$info-hover: var(--#{$variable-prefix}info-hover);
$warning-hover: var(--#{$variable-prefix}warning-hover);
$danger-hover: var(--#{$variable-prefix}danger-hover);
$light-hover: var(--#{$variable-prefix}light-hover);
$dark-hover: var(--#{$variable-prefix}dark-hover);

$primary-hover-contrast: var(--#{$variable-prefix}primary-hover-contrast);
$secondary-hover-contrast: var(--#{$variable-prefix}secondary-hover-contrast);
$success-hover-contrast: var(--#{$variable-prefix}success-hover-contrast);
$info-hover-contrast: var(--#{$variable-prefix}info-hover-contrast);
$warning-hover-contrast: var(--#{$variable-prefix}warning-hover-contrast);
$danger-hover-contrast: var(--#{$variable-prefix}danger-hover-contrast);
$light-hover-contrast: var(--#{$variable-prefix}light-hover-contrast);
$dark-hover-contrast: var(--#{$variable-prefix}dark-hover-contrast);

$primary-light: var(--#{$variable-prefix}primary-light);
$secondary-light: var(--#{$variable-prefix}secondary-light);
$success-light: var(--#{$variable-prefix}success-light);
$info-light: var(--#{$variable-prefix}info-light);
$warning-light: var(--#{$variable-prefix}warning-light);
$danger-light: var(--#{$variable-prefix}danger-light);

$primary-light-rgb: var(--#{$variable-prefix}primary-light-rgb);
$secondary-light-rgb: var(--#{$variable-prefix}secondary-light-rgb);
$success-light-rgb: var(--#{$variable-prefix}success-light-rgb);
$info-light-rgb: var(--#{$variable-prefix}info-light-rgb);
$warning-light-rgb: var(--#{$variable-prefix}warning-light-rgb);
$danger-light-rgb: var(--#{$variable-prefix}danger-light-rgb);

$primary-dark: var(--#{$variable-prefix}primary-dark);
$secondary-dark: var(--#{$variable-prefix}secondary-dark);
$success-dark: var(--#{$variable-prefix}success-dark);
$info-dark: var(--#{$variable-prefix}info-dark);
$warning-dark: var(--#{$variable-prefix}warning-dark);
$danger-dark: var(--#{$variable-prefix}danger-dark);

$primary-dark-rgb: var(--#{$variable-prefix}primary-dark-rgb);
$secondary-dark-rgb: var(--#{$variable-prefix}secondary-dark-rgb);
$success-dark-rgb: var(--#{$variable-prefix}success-dark-rgb);
$info-dark-rgb: var(--#{$variable-prefix}info-dark-rgb);
$warning-dark-rgb: var(--#{$variable-prefix}warning-dark-rgb);
$danger-dark-rgb: var(--#{$variable-prefix}danger-dark-rgb);

$primary-light-contrast: var(--#{$variable-prefix}primary-light-contrast);
$secondary-light-contrast: var(--#{$variable-prefix}secondary-light-contrast);
$success-light-contrast: var(--#{$variable-prefix}success-light-contrast);
$info-light-contrast: var(--#{$variable-prefix}info-light-contrast);
$warning-light-contrast: var(--#{$variable-prefix}warning-light-contrast);
$danger-light-contrast: var(--#{$variable-prefix}danger-light-contrast);

$primary-dark-contrast: var(--#{$variable-prefix}primary-dark-contrast);
$secondary-dark-contrast: var(--#{$variable-prefix}secondary-dark-contrast);
$success-dark-contrast: var(--#{$variable-prefix}success-dark-contrast);
$info-dark-contrast: var(--#{$variable-prefix}info-dark-contrast);
$warning-dark-contrast: var(--#{$variable-prefix}warning-dark-contrast);
$danger-dark-contrast: var(--#{$variable-prefix}danger-dark-contrast);

$primary-accessible: var(--#{$variable-prefix}primary-accessible);
$secondary-accessible: var(--#{$variable-prefix}secondary-accessible);
$success-accessible: var(--#{$variable-prefix}success-accessible);
$info-accessible: var(--#{$variable-prefix}info-accessible);
$warning-accessible: var(--#{$variable-prefix}warning-accessible);
$danger-accessible: var(--#{$variable-prefix}danger-accessible);

$primary-bg-accessible: var(--#{$variable-prefix}primary-bg-accessible);
$secondary-bg-accessible: var(--#{$variable-prefix}secondary-bg-accessible);
$success-bg-accessible: var(--#{$variable-prefix}success-bg-accessible);
$info-bg-accessible: var(--#{$variable-prefix}info-bg-accessible);
$warning-bg-accessible: var(--#{$variable-prefix}warning-bg-accessible);
$danger-bg-accessible: var(--#{$variable-prefix}danger-bg-accessible);

$theme-colors: (
    'primary': $primary,
    'secondary': $secondary,
    'success': $success,
    'info': $info,
    'warning': $warning,
    'danger': $danger,
    'light': $light,
    'dark': $dark,
);

$theme-colors-contrast: (
    'primary': $primary-contrast,
    'secondary': $secondary-contrast,
    'success': $success-contrast,
    'info': $info-contrast,
    'warning': $warning-contrast,
    'danger': $danger-contrast,
    'light': $light-contrast,
    'dark': $dark-contrast,
);

$theme-colors-hover: (
    'primary': $primary-hover,
    'secondary': $secondary-hover,
    'success': $success-hover,
    'info': $info-hover,
    'warning': $warning-hover,
    'danger': $danger-hover,
    'light': $light-hover,
    'dark': $dark-hover,
);

$theme-colors-hover-contrast: (
    'primary': $primary-hover-contrast,
    'secondary': $secondary-hover-contrast,
    'success': $success-hover-contrast,
    'info': $info-hover-contrast,
    'warning': $warning-hover-contrast,
    'danger': $danger-hover-contrast,
    'light': $light-hover-contrast,
    'dark': $dark-hover-contrast,
);

$theme-colors-light: (
    'primary': $primary-light,
    'secondary': $secondary-light,
    'success': $success-light,
    'info': $info-light,
    'warning': $warning-light,
    'danger': $danger-light,
);

$theme-colors-dark: (
    'primary': $primary-dark,
    'secondary': $secondary-dark,
    'success': $success-dark,
    'info': $info-dark,
    'warning': $warning-dark,
    'danger': $danger-dark,
);

$theme-colors-light-contrast: (
    'primary': $primary-light-contrast,
    'secondary': $secondary-light-contrast,
    'success': $success-light-contrast,
    'info': $info-light-contrast,
    'warning': $warning-light-contrast,
    'danger': $danger-light-contrast,
);

$theme-colors-dark-contrast: (
    'primary': $primary-dark-contrast,
    'secondary': $secondary-dark-contrast,
    'success': $success-dark-contrast,
    'info': $info-dark-contrast,
    'warning': $warning-dark-contrast,
    'danger': $danger-dark-contrast,
);

$theme-colors-accessible: (
    'primary': $primary-accessible,
    'secondary': $secondary-accessible,
    'success': $success-accessible,
    'info': $info-accessible,
    'warning': $warning-accessible,
    'danger': $danger-accessible,
);

// Set transparent as rgba for browser compatibility
$transparent: rgba(0, 0, 0, 0);

// Body
$body-bg: var(--#{$variable-prefix}body-bg);
$body-bg-muted: var(--#{$variable-prefix}body-bg-muted);
$body-bg-strong: var(--#{$variable-prefix}body-bg-strong);
$body-color: var(--#{$variable-prefix}body-color);
$body-color-muted: var(--#{$variable-prefix}body-color-muted);
$body-color-strong: var(--#{$variable-prefix}body-color-strong);

$body-bg-rgb: var(--#{$variable-prefix}body-bg-rgb);
$body-bg-rgb-muted: var(--#{$variable-prefix}body-bg-rgb-muted);
$body-bg-rgb-bold: var(--#{$variable-prefix}body-bg-rgb-bold);
$body-color-rgb: var(--#{$variable-prefix}body-color-rgb);
$body-color-rgb-muted: var(--#{$variable-prefix}body-color-rgb-muted);
$body-color-rgb-bold: var(--#{$variable-prefix}body-color-rgb-bold);

// Links
$link-color: var(--#{$variable-prefix}link-color);
$link-hover-color: var(--#{$variable-prefix}link-color-hover);

// Components
$border-color: #{var(--#{$variable-prefix}border-color)};
$border-color-muted: #{var(--#{$variable-prefix}border-color-muted)};

$box-shadow: 0 0.5rem 1rem rgba(#{$black-rgb}, 0.15);
$box-shadow-sm: 0 0.125rem 0.25rem rgba(#{$black-rgb}, 0.075);
$box-shadow-lg: 0 1rem 3rem rgba(#{$black-rgb}, 0.175);
$box-shadow-inset: inset 0 1px 2px rgba(#{$black-rgb}, 0.075);

$component-active-color: #{var(--#{$variable-prefix}component-active-color)};
$component-active-bg: #{var(--#{$variable-prefix}component-active-bg)};
$component-active-bg-rgb: #{var(--#{$variable-prefix}component-active-bg-rgb)};

// Typography
$text-muted: $body-color-muted;

// Header
$header-bg: var(
    --#{$variable-prefix}header-bg,
    var(--#{$variable-prefix}primary)
);
$header-color: var(
    --#{$variable-prefix}header-bg,
    var(--#{$variable-prefix}primary-contrast)
);

// Tables
$table-color: $body-color;
$table-bg: transparent;
$table-accent-bg: transparent;

$table-striped-color: $table-color;
$table-striped-bg: rgba($black, $table-striped-bg-factor);

$table-active-color: $table-color;
$table-active-bg: rgba($black, $table-active-bg-factor);

$table-hover-color: $table-color;
$table-hover-bg: rgba($black, $table-hover-bg-factor);

$table-border-color: $border-color;
$table-group-separator-color: $table-border-color;

$table-caption-color: $text-muted;

// Buttons + Forms
$input-btn-focus-color: rgba(
    #{$component-active-bg-rgb},
    $input-btn-focus-color-opacity
);
$input-btn-focus-box-shadow: 0 0 $input-btn-focus-blur $input-btn-focus-width
    $input-btn-focus-color;

// Buttons
$btn-focus-box-shadow: $input-btn-focus-box-shadow;
$btn-active-box-shadow: inset 0 3px 5px rgba(#{$black-rgb}, 0.125);

$btn-link-color: $link-color;
$btn-link-hover-color: $link-hover-color;
$btn-link-disabled-color: $body-color-muted;

// Forms
$form-text-color: $text-muted;

$form-label-color: null;

$input-bg: $body-bg;
$input-disabled-bg: $body-bg-strong;
$input-disabled-border-color: null;

$input-active-color: $primary-contrast;
$input-active-bg: $primary;

$input-color: $body-color;
$input-border-color: $gray-400;
$input-box-shadow: $box-shadow-inset;

$input-focus-bg: $input-bg;
$input-focus-border-color: $input-active-bg;
$input-focus-color: $input-color;
$input-focus-box-shadow: $input-btn-focus-box-shadow;

$input-placeholder-color: $body-color-muted;
$input-plaintext-color: $body-color;

$input-switch-container-bg: $body-bg-muted;
$input-switch-container-active-bg: $input-active-bg;
$input-switch-container-active-disabled-bg: $primary-light;
$input-switch-container-border-color: $body-bg-strong;
$input-switch-container-border: $input-switch-container-border-width solid
    $input-switch-container-border-color;
$input-switch-container-border-active-color: $input-switch-container-active-bg;
$input-switch-container-border-active-disabled-color: $input-switch-container-active-disabled-bg;

$input-switch-bg: $body-bg;
$input-switch-border: $input-switch-container-border-width solid $body-bg-strong;

$form-check-label-color: null;

$form-check-input-bg: $input-bg;
$form-check-input-border: 1px solid $input-border-color;
$form-check-input-focus-border: $input-focus-border-color;
$form-check-input-focus-box-shadow: $input-btn-focus-box-shadow;

$form-check-input-checked-color: $input-active-color;
$form-check-input-checked-bg-color: $input-active-bg;
$form-check-input-checked-border-color: $form-check-input-checked-bg-color;
$form-check-input-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path fill='none' stroke='#{$form-check-input-checked-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/></svg>");
$form-check-radio-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='2' fill='#{$form-check-input-checked-color}'/></svg>");

$form-check-input-indeterminate-color: $input-active-color;
$form-check-input-indeterminate-bg-color: $input-active-bg;
$form-check-input-indeterminate-border-color: $form-check-input-indeterminate-bg-color;
$form-check-input-indeterminate-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path fill='none' stroke='#{$form-check-input-indeterminate-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/></svg>");

$form-check-tile-border-color-focus: $input-active-bg;

$form-switch-color: rgba($black, 0.25);
$form-switch-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-switch-color}'/></svg>");

$form-switch-focus-color: $input-focus-border-color;
$form-switch-focus-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-switch-focus-color}'/></svg>");

$form-switch-checked-color: $component-active-color;
$form-switch-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-switch-checked-color}'/></svg>");

$input-group-addon-color: $body-color;
$input-group-addon-bg: $body-bg-strong;
$input-group-addon-border-color: $input-border-color;

$form-select-color: $input-color;
$form-select-bg: $input-bg;
$form-select-disabled-color: null;
$form-select-disabled-bg: $gray-200;
$form-select-disabled-border-color: $input-disabled-border-color;
$form-select-indicator-color: $gray-800;
$form-select-indicator: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='#{$form-select-indicator-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/></svg>");

$form-select-border-color: $input-border-color;
$form-select-box-shadow: $box-shadow-inset;

$form-select-focus-border-color: $input-focus-border-color;
$form-select-focus-box-shadow: 0 0 0 $form-select-focus-width
    $input-btn-focus-color;

$form-range-track-bg: $gray-300;
$form-range-track-box-shadow: $box-shadow-inset;

$form-range-thumb-bg: $component-active-bg;
$form-range-thumb-box-shadow: 0 0.1rem 0.25rem rgba($black, 0.1);
$form-range-thumb-focus-box-shadow: 0 0 0 1px $body-bg, $input-focus-box-shadow;
$form-range-thumb-active-bg: tint-color($component-active-bg, 70%);
$form-range-thumb-disabled-bg: $gray-500;

$form-file-button-color: $input-color;
$form-file-button-bg: $input-group-addon-bg;
$form-file-button-hover-bg: $form-file-button-bg;

// Form validation
$form-feedback-valid-color: $success;
$form-feedback-invalid-color: $danger;
$form-feedback-warning-color: $warning;

$form-feedback-icon-valid-color: $form-feedback-valid-color;
$form-feedback-icon-valid: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'><path fill='#{$form-feedback-icon-valid-color}' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/></svg>");
$form-feedback-icon-invalid-color: $form-feedback-invalid-color;
$form-feedback-icon-invalid: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='#{$form-feedback-icon-invalid-color}'><circle cx='6' cy='6' r='4.5'/><path stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/><circle cx='6' cy='8.2' r='.6' fill='#{$form-feedback-icon-invalid-color}' stroke='none'/></svg>");

$form-validation-states: (
    'valid': (
        'color': $form-feedback-valid-color,
        'icon': $form-feedback-icon-valid,
    ),
    'invalid': (
        'color': $form-feedback-invalid-color,
        'icon': $form-feedback-icon-invalid,
    ),
);

// Navs
$nav-link-color: $body-color-muted;
$nav-link-hover-color: $link-hover-color;
$nav-link-disabled-color: $body-color-muted;

$nav-tabs-border-color: $transparent;
$nav-tabs-link-border: $nav-tabs-link-border-width solid $nav-tabs-border-color;
$nav-tabs-link-hover-border-color: $transparent;
$nav-tabs-link-hover-bg: $body-bg-muted;
$nav-tabs-link-active-color: $primary-accessible;
$nav-tabs-link-active-bg: $nav-tabs-link-hover-bg;
$nav-tabs-link-active-border-color: $nav-tabs-link-active-color;

$nav-pills-link-active-color: $component-active-color;
$nav-pills-link-active-bg: $component-active-bg;

// Navbar
$navbar-dark-color: rgba($white, 0.55);
$navbar-dark-hover-color: rgba($white, 0.75);
$navbar-dark-active-color: $white;
$navbar-dark-disabled-color: rgba($white, 0.25);
$navbar-dark-toggler-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='#{$navbar-dark-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>");
$navbar-dark-toggler-border-color: rgba($white, 0.1);

$navbar-light-color: rgba($black, 0.55);
$navbar-light-hover-color: rgba($black, 0.7);
$navbar-light-active-color: rgba($black, 0.9);
$navbar-light-disabled-color: rgba($black, 0.3);
$navbar-light-toggler-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='#{$navbar-light-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>") !default;
$navbar-light-toggler-border-color: rgba($black, 0.1) !default;

$navbar-light-brand-color: $navbar-light-active-color;
$navbar-light-brand-hover-color: $navbar-light-active-color;
$navbar-dark-brand-color: $navbar-dark-active-color;
$navbar-dark-brand-hover-color: $navbar-dark-active-color;

// Dropdowns
$dropdown-color: $body-color;
$dropdown-bg: $body-bg;
$dropdown-border-color: $transparent;
$dropdown-border: map-get($border-widths, 1) solid $border-color-muted;
$dropdown-divider-bg: $border-color-muted;
$dropdown-box-shadow: $box-shadow;

$dropdown-link-color: $link-color;
$dropdown-link-hover-color: $link-hover-color;
$dropdown-link-hover-bg: $body-bg-muted;

$dropdown-link-active-color: $component-active-color;
$dropdown-link-active-bg: $component-active-bg;

$dropdown-link-disabled-color: $gray-500;

$dropdown-header-color: $dropdown-color;

$dropdown-dark-color: $gray-300;
$dropdown-dark-bg: $gray-800;
$dropdown-dark-border-color: $dropdown-border-color;
$dropdown-dark-divider-bg: $dropdown-divider-bg;
$dropdown-dark-box-shadow: null;
$dropdown-dark-link-color: $dropdown-dark-color;
$dropdown-dark-link-hover-color: $white;
$dropdown-dark-link-hover-bg: rgba($white, 0.15);
$dropdown-dark-link-active-color: $dropdown-link-active-color;
$dropdown-dark-link-active-bg: $dropdown-link-active-bg;
$dropdown-dark-link-disabled-color: $gray-500;
$dropdown-dark-header-color: $gray-500;

// Pagination
$pagination-color: $link-color;
$pagination-bg: $white;
$pagination-border-color: $gray-300;

$pagination-focus-color: $link-hover-color;
$pagination-focus-bg: $gray-200;
$pagination-focus-box-shadow: $input-btn-focus-box-shadow;
$pagination-focus-outline: 0;

$pagination-hover-color: $link-hover-color;
$pagination-hover-bg: $gray-200;
$pagination-hover-border-color: $gray-300;

$pagination-active-color: $component-active-color;
$pagination-active-bg: $component-active-bg;
$pagination-active-border-color: $pagination-active-bg;

$pagination-disabled-color: $body-color-muted;
$pagination-disabled-bg: $white;
$pagination-disabled-border-color: $gray-300;

// Cards
$card-border-color: $border-color;
$card-box-shadow: null;
$card-color: $body-color;
$card-bg: $body-bg;
$card-cap-bg: $card-bg;
$card-cap-color: $card-color;

// Accordion
$accordion-color: $body-color;
$accordion-bg: $body-bg;

$accordion-button-color: $accordion-color;
$accordion-button-bg: $accordion-bg;
$accordion-button-active-bg: $accordion-button-bg;
$accordion-button-active-color: $accordion-button-color;

$accordion-button-focus-border-color: $input-focus-border-color;
$accordion-button-focus-box-shadow: $btn-focus-box-shadow;

$accordion-icon-color: $accordion-button-color;
$accordion-icon-active-color: $accordion-button-active-color;

$accordion-button-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$accordion-icon-color}'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");
$accordion-button-active-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$accordion-icon-active-color}'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");

// Tooltips
$tooltip-color: $white;
$tooltip-bg: $black;

$tooltip-arrow-color: $tooltip-bg;

// Popovers
$popover-bg: $body-bg;
$popover-border-color: $body-bg;
$popover-box-shadow: $box-shadow-lg;

$popover-header-bg: $popover-bg;
$popover-header-color: $headings-color;

$popover-body-color: $body-color;

$popover-arrow-color: $popover-bg;

$popover-arrow-outer-color: $popover-border-color;

// Toasts
$toast-color: $body-color;
$toast-background-color: $body-bg;
$toast-border-color: $transparent;
$toast-box-shadow: $box-shadow;

$toast-header-color: $toast-color;
$toast-header-background-color: $toast-background-color;
$toast-header-border-color: $border-color;

// Badges
$badge-bg: $transparent;
$badge-color: $body-color;

// Modals
$modal-content-color: $body-color;
$modal-content-bg: $body-bg;
$modal-content-border-color: $border-color;
$modal-content-box-shadow-xs: $box-shadow-sm;
$modal-content-box-shadow-sm-up: $box-shadow;

$modal-backdrop-bg: $black;
$modal-header-border-color: $border-color;
$modal-footer-border-color: $modal-header-border-color;
$modal-header-border-width: $modal-content-border-width;
$modal-footer-border-width: $modal-header-border-width;

// Alerts
$alert-bg: $body-bg-muted;
$alert-color: $body-color;

// Progress bars
$progress-bg: $gray-200;
$progress-box-shadow: $box-shadow-inset;
$progress-bar-color: $white;
$progress-bar-bg: $primary;

// List group
$list-group-color: $body-color;
$list-group-bg: $body-bg;
$list-group-border-color: $border-color;

$list-group-hover-bg: $body-bg-muted;
$list-group-active-color: $component-active-color;
$list-group-active-bg: $component-active-bg;
$list-group-active-border-color: $list-group-border-color;

$list-group-disabled-color: $body-color-muted;
$list-group-disabled-bg: $list-group-bg;

$list-group-action-color: $body-color;
$list-group-action-hover-color: $list-group-action-color;

$list-group-action-active-color: $list-group-active-color;
$list-group-action-active-bg: $list-group-active-bg;

// Image thumbnails
$thumbnail-bg: $body-bg;
$thumbnail-border-color: $gray-300;
$thumbnail-box-shadow: $box-shadow-sm;

// Figures
$figure-caption-color: $body-color-muted;

// List groups
$list-group-action-color: $link-color;
$list-group-action-active-color: $link-color;
$list-group-action-hover-color: $link-hover-color;

// Breadcrumbs
$breadcrumb-bg: null;
$breadcrumb-divider-color: $body-color-muted;
$breadcrumb-active-color: $body-color-muted;

// Carousel
$carousel-control-color: $body-color;

$carousel-indicator-active-bg: $primary;

$carousel-caption-color: $light;

$carousel-control-prev-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$carousel-control-color}'><path d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/></svg>");
$carousel-control-next-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$carousel-control-color}'><path d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/></svg>");

$carousel-dark-indicator-active-bg: $primary;
$carousel-dark-caption-color: $dark;

// Close
$btn-close-color: $black;
$btn-close-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$btn-close-color}'><path d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/></svg>");
$btn-close-focus-shadow: $input-btn-focus-box-shadow;

// Offcanvas
$offcanvas-border-color: $modal-content-border-color;
$offcanvas-bg-color: $modal-content-bg;
$offcanvas-color: $modal-content-color;
$offcanvas-box-shadow: $modal-content-box-shadow-xs;
$offcanvas-backdrop-bg: $modal-backdrop-bg;

// Code
$code-color: $pink;

$kbd-color: $white;
$kbd-bg: $gray-900;

$pre-color: null;

// Divider
$divider-color: $border-color;

// Icons
$icon-default-bg: $body-bg-strong;
$icon-default-color: $body-color-strong;

// Avatars
$avatar-default-bg: $icon-default-bg;
$avatar-default-color: $icon-default-color;

$avatar-group-border: $avatar-group-border-width solid
    var(--#{$variable-prefix}avatar-group-border-color);

// Timeline
$timeline-line-color: $body-bg-strong;

$timeline-icon-bg: $body-bg;
$timeline-icon-color: $primary-light;
$timeline-icon-border: $timeline-line-width solid $timeline-icon-bg;

$timeline-line-color: $timeline-icon-color;

// Widgets
$widget-color: $body-color;
$widget-bg: $body-bg;
$widget-border-color: $transparent;

$widget-cap-color: $widget-color;
$widget-cap-bg: $widget-bg;
$widget-cap-border-color: $border-color;
$widget-cap-border: $widget-cap-border-width solid $widget-cap-border-color;

// Utilities
$clmi-variable-prefix: clmi-;

// Scrollbar
$scrollbar-bg: $body-bg-strong;
$scrollbar-thumb-bg: $gray-400;
$scrollbar-thumb-bg-hover: $gray-500;

// Date/time picker
$datepicker-bg: $body-bg;

$datepicker-select-bg: $primary;
$datepicker-select-color: $primary-contrast;

$datepicker-hover-bg: $component-active-bg;
$datepicker-hover-color: $component-active-color;

$datepicker-range-bg: $datepicker-hover-bg;
$datepicker-range-color: $datepicker-hover-color;

$datepicker-month-border: $border-color solid map-get($border-widths, 1);

$timepicker-border: $datepicker-month-border;

// Loaders
$skeleton-loader-bg: $body-bg-strong;
$skeleton-loader-bg-highlight: $body-bg-muted;

$dot-loader-bg: $body-bg-strong;
$dot-loader-bg-active: $primary;
