$icon-color-excel: #1f7246;
$icon-color-image: #1a4a8b;
$icon-color-pdf: #ff0000;
$icon-color-powerpoint: #d04626;
$icon-color-word: #2b5797;

@each $size, $value in $icon-sizes {
    .icon-size-#{$size} {
        height: $value;
        width: $value;
        font-size: $value !important;
    }
}

@each $size, $value in $icon-container-sizes {
    .icon-container-size-#{$size} {
        height: $value;
        width: $value;
        font-size: map-get($icon-sizes, $size);
    }
}

.bg-icon {
    background-color: $icon-default-bg;
    color: $icon-default-color;
}

.icon-shift-up {
    margin-bottom: 0.2rem;
}

.icon-shift-down {
    margin-top: 0.2rem;
}

.icon-shift-left {
    margin-right: 0.1rem;

    @include media-breakpoint-down(md) {
        margin-right: 0;
    }
}
