// List Groups

// scss-docs-start list-group-mixin
@mixin list-group-item-variant($state) {
    $bg: map-get($theme-colors-light, $state);
    $color: map-get($theme-colors-light-contrast, $state);
    $bg-hover: map-get($theme-colors, $state);
    $color-hover: map-get($theme-colors-contrast, $state);
    $bg-active: $bg-hover;
    $color-active: $color-hover;

    .list-group-item-#{$state} {
        color: $color;
        background-color: $bg;

        &.list-group-item-action {
            &:hover,
            &:focus {
                color: $color-hover;
                background-color: $bg-hover;
            }

            &.active {
                color: $color-active;
                background-color: $bg-active;
                border-color: $color-active;
            }
        }
    }
}
// scss-docs-end list-group-mixin
