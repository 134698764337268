@import 'bootstrap/scss/badge';

.badge {
    background-color: $badge-bg;
    border: $badge-border-width solid $badge-color;
}

@mixin set-badge-variants($colorMap, $contrastMap, $suffix: null) {
    @each $color, $value in $colorMap {
        .badge-#{$color}#{$suffix} {
            background-color: $value;
            color: map-get($contrastMap, $color);
            border-color: $value;
        }
    }
}

@include set-badge-variants($theme-colors, $theme-colors-contrast);
@include set-badge-variants(
    $theme-colors-light,
    $theme-colors-light-contrast,
    -light
);
@include set-badge-variants(
    $theme-colors-dark,
    $theme-colors-dark-contrast,
    -dark
);
